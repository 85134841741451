import styled, { CSSProperties, css } from 'styled-components';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import Flex from '../Flex/Flex';
import Icon from '../Icon/Icon';

type MarkType = 'state' | 'arrow' | 'check' | 'download';
type MarkColor = 'blue' | 'green' | 'red' | 'lightGray' | 'gray' | 'yellow' | 'purple';

interface MarkProps {
  children: React.ReactNode;
  type?: MarkType;
  color: MarkColor;
  iconColor?: keyof typeof palette;
  textColor?: keyof typeof palette;
  closable?: boolean;
  onClick?: VoidFunction;
  style?: CSSProperties;
}

const Mark = ({
  children,
  type = 'state',
  color,
  iconColor,
  textColor,
  closable = false,
  onClick,
  style,
}: MarkProps) => {
  const getTextColor = () => {
    switch (color) {
      case 'blue':
        return 'PRIMARY50';
      case 'green':
        return 'MESSAGE_SUCCESS';
      case 'red':
        return 'MESSAGE_ERROR';
      case 'lightGray':
        return 'GRAY50';
      case 'gray':
        return 'GRAY50';
      case 'yellow':
        return 'YELLOW_70';
      case 'purple':
        return 'PURPLE_50';
    }
  };

  const getBackgroundColor = () => {
    switch (color) {
      case 'blue':
        return palette.PRIMARY30;
      case 'green':
        return palette.GREEN_20;
      case 'red':
        return palette.SECONDARY_20;
      case 'lightGray':
        return palette.GRAY20;
      case 'gray':
        return palette.GRAY30;
      case 'yellow':
        return palette.YELLOW_20;
      case 'purple':
        return palette.PURPLE_20;
    }
  };

  return (
    <Block
      type={type}
      justify="center"
      align="center"
      closable={closable}
      onClick={onClick}
      style={{
        background: getBackgroundColor(),
        cursor: onClick ? 'pointer' : 'default',
        ...style,
      }}
    >
      <Typography.Text type="SMALL" color={textColor || getTextColor()} medium>
        {children}
      </Typography.Text>
      {type !== 'state' && (
        <Icon
          name={type === 'arrow' ? 'right' : type === 'check' ? 'check' : 'download'}
          color={iconColor || getTextColor()}
          size={12}
        />
      )}
    </Block>
  );
};

export default Mark;

const Block = styled(Flex)<{ type: MarkType; closable: boolean }>`
  border-radius: 4px;
  width: max-content;
  margin: 0 auto;

  ${({ type }) =>
    type === 'arrow' || type === 'download'
      ? css`
          padding: 4px 4px 4px 8px;
          gap: 2px;
        `
      : css`
          padding: 4px 8px;
          gap: 4px;
        `}

  ${({ closable }) =>
    closable &&
    css`
      &:hover::after {
        content: '×';
        display: inline-block;
        padding-left: 4px;
        line-height: 0;
        color: ${palette.PRIMARY50};
      }
    `};
`;
