import { useMemo } from 'react';
import styled from 'styled-components';
import { Modal, Steps } from 'antd';

import path from 'lib/path';

export interface CertificateProgressModalProps {
  countryName: string;
  currentStep: string;
  onClose?: VoidFunction;
}

const EnhancedModal = styled(Modal)<{
  direction: 'vertical' | 'horizontal';
}>`
  ${({ direction }) =>
    direction === 'vertical' &&
    `
  .ant-steps-item-title {
    margin-bottom: 10px;
  }

  .ant-steps-item-description {
    height: 40px;
    padding-bottom: 20px !important;
    box-sizing: content-box;
  }
  `}
`;

const CertificateProgressModal = ({
  countryName,
  currentStep,
  onClose,
}: CertificateProgressModalProps) => {
  const direction = useMemo(
    () => (countryName === '중국' ? 'vertical' : 'horizontal'),
    [countryName],
  );

  const steps = useMemo(() => {
    if (direction === 'vertical') {
      return [
        {
          code: 'REG-FIN',
          title: '접수 완료',
          content: '해당 제품의 접수가 완료되었습니다.',
        },
        {
          code: 'CHK-DAS',
          title: '서류 검토 / 샘플 검역',
          content:
            '현지 에이전시 혹은 바이어가 정보 검토 중, 또는 샘플을 현지 국가로 배송 중에 있거나, 현지 검역소에서 검역 중에 있습니다.',
        },
        {
          code: 'WRI-REP',
          title: '보고서 작성',
          content:
            '제출한 서류들과 샘플을 토대로 안전성 평가 및 제품 검사를 실시하고 검역 보고서와 안전성 보고서를 작성 중에 있습니다.',
        },
        {
          code: 'REG-SYS',
          title: '시스템 등록',
          content:
            '최종 심사 자료 중 자유판매증명서(CFS), 생산위임계약서의 원본 서류가 배송 중에 있거나, NMPA 시스템 등록 중에 있습니다.',
        },
        {
          code: 'FDA-AUD',
          title: '식약국 심사',
          content: '모든 자료를 제출하여 최종 심사 중이며 보완이 있을 수 있습니다.',
        },
        {
          code: 'REG-SUC',
          title: '등록 완료',
          content: (
            <>
              <span>NMPA 등록이 완료되어 해당 제품이 </span>{' '}
              <a
                href={path.certificate.complete}
                onClick={onClose}
                style={{ textDecoration: 'underline' }}
              >
                인증 완료 제품 관리
              </a>
              <span>로 이동됩니다.</span>
              <br />
              <span>해당 메뉴에서 전자 위생허가증을 포함한 자료들의 다운로드가 가능합니다.</span>
            </>
          ),
        },
      ];
    }

    return [
      {
        code: 'REG-FIN',
        title: '접수 완료',
        content: '해당 제품의 접수가 완료되었습니다.',
      },
      {
        code: 'CHK-DOC',
        title: '서류 검토중',
        content:
          '입력하신 정보와 업로드 하신 자료들을 현지 에이전시 혹은 바이어가 정보 검토 중에 있습니다.',
      },
      {
        code: 'CHK-SAM',
        title: '샘플 검역중',
        content: '샘플을 현지 국가로 배송중에 있거나, 현지 식약처에서 검역중에 있습니다.',
      },
      {
        code: 'CER-FIN',
        title: '인증 완료',
        content: (
          <>
            <span>인증이 완료되어 해당 제품이</span>{' '}
            <a
              href={path.certificate.complete}
              onClick={onClose}
              style={{ textDecoration: 'underline' }}
            >
              인증 완료 제품 관리
            </a>
            <span>로 이동됩니다.</span>
          </>
        ),
      },
    ];
  }, [direction]);

  const currentStepIndex = steps.findIndex((step) => step.code === currentStep);

  return (
    <EnhancedModal
      direction={direction}
      title="certicos 진행 상태 단계별 안내"
      footer={null}
      width={direction === 'vertical' ? 600 : 1000}
      visible={currentStep !== null}
      onCancel={onClose}
    >
      <Steps direction={direction}>
        {steps.map(({ code, title, content }, index) => {
          const status =
            currentStepIndex === index ? 'process' : currentStepIndex > index ? 'finish' : 'wait';
          return (
            <Steps.Step
              key={code}
              title={title}
              status={status}
              description={direction === 'vertical' ? content : undefined}
            />
          );
        })}
      </Steps>
      {direction === 'horizontal' && (
        <ol style={{ paddingLeft: 16, marginTop: 32 }}>
          {steps.map(({ code, title, content }) => (
            <li key={code} style={{ marginBottom: 4 }}>
              {title} : {content}
            </li>
          ))}
        </ol>
      )}
    </EnhancedModal>
  );
};

export default CertificateProgressModal;
