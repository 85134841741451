import { Button, Upload, message } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import { useModal } from 'hook/useModal';
import { downloadFile } from 'lib/file';
import palette from 'lib/styles/palette';
import { FormulaScreeningItem } from 'service/brand/formulaScreening/formulaScreening';

const FormulaScreening = ({
  file,
  setFile,
  formulaScreeningResult,
  uploadLoading,
}: {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  formulaScreeningResult?: FormulaScreeningItem | null;
  uploadLoading: boolean;
}) => {
  const { openAlertModal, openConfirmModal } = useModal();

  const success = formulaScreeningResult ? !formulaScreeningResult.isRowError : undefined;

  const handleUpload = (file: File) => {
    setFile(file);
  };

  const handleInit = () => {
    if (!file) {
      return message.warning('입력한 내용이 없습니다.');
    }
    openAlertModal({
      content: '초기화 하시겠습니까?',
      onOk: () => {
        setFile(null);
      },
      okText: '초기화',
    });
  };

  const handleShowUploadExampleModal = () => {
    openConfirmModal({
      title: <Typography.Text type="TITLE_2">업로드 예시</Typography.Text>,
      width: 640,
      content: (
        <img
          src="https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/document_example/Formula+Breakdown+Screening.png"
          alt="formula-screening"
          width={560}
          height={476}
        />
      ),
      footer: null,
    });
  };

  return (
    <Container success={success}>
      <LoadingModal
        visible={uploadLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <Flex
        gap={8}
        style={{
          marginTop: 56,
          paddingBottom: 8,
          borderBottom: `2px solid ${palette.PRIMARY50}`,
          width: '100%',
        }}
        align="center"
        justify="space-between"
      >
        <Flex gap={8} align="center">
          <Flex gap={4} align="center">
            <Icon name="screening" size={24} />
            <Typography.Text type="TITLE_1">성분 스크리닝 정보 입력</Typography.Text>
          </Flex>
          <SmallGrayButton onClick={handleInit}>
            <Icon name="refresh" size={16} color="SLATE_GRAY60" />
            초기화
          </SmallGrayButton>
        </Flex>
        <SmallGrayButton
          type="button"
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Ingredients_Screening_Form.xlsx',
            )
          }
        >
          <Icon name="download" size={16} color="SLATE_GRAY60" />
          엑셀 서식 다운로드
        </SmallGrayButton>
      </Flex>
      <DescriptionContainer gap={4} dir="column" style={{ height: 72 }}>
        <Flex align="center" gap={8}>
          <Typography.Text type="BODY_2">
            · certicos에서 제공하는 엑셀 서식 파일을 다운로드하여 정보를 알맞게 기입한 후 업로드해
            주세요.
          </Typography.Text>
          <ExampleButton justify="center" align="center" onClick={handleShowUploadExampleModal}>
            업로드 예시 보기
          </ExampleButton>
        </Flex>
        <Typography.Text style={{ fontSize: 14 }}>
          · CAS No. 가 없거나 모르시는 경우는 입력하지 않으셔도 됩니다.
        </Typography.Text>
      </DescriptionContainer>
      <Flex gap={8} align="center" style={{ marginTop: 24 }}>
        <Upload
          accept=".xlsx"
          beforeUpload={(file) => {
            handleUpload(file);
            if (file) message.success(`정상적으로 업로드되었습니다.`);
            return false;
          }}
          itemRender={() => null}
        >
          <Flex align="center" gap={8}>
            <Button
              loading={uploadLoading}
              icon={<Icon name="upload" size={18} color={'PRIMARY50'} />}
              style={{
                width: 122,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onDrop={(e) => e.stopPropagation()}
            >
              파일 업로드
            </Button>
            {file && (
              <Typography.Text type="BODY_2" style={{ color: palette.GRAY90 }}>
                {file?.name}
              </Typography.Text>
            )}
          </Flex>
        </Upload>
      </Flex>
    </Container>
  );
};

const Container = styled.div<{
  success?: boolean;
}>`
  width: 100%;
  position: relative;

  .ant-descriptions-view {
    border-radius: 0;
    border: none;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border-right: none;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    vertical-align: top;
    padding: 16px;
  }

  .ant-descriptions-item-content {
    padding: 24px;
  }
`;

const SmallGrayButton = styled.button`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  color: ${palette.SLATE_GRAY70};
  background-color: ${palette.SLATE_GRAY30};
  padding: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const ExampleButton = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${palette.PRIMARY50};
  background: white;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.GRAY90};
  height: 24px;
  cursor: pointer;
  padding: 0 8px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: ${palette.PRIMARY50};
  }
`;

export default FormulaScreening;
