import { useEffect } from 'react';
import { Button, Form, Input, Spin } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { useArtworkScreeningAllergens } from 'service/brand/artworkScreening/artworkScreening';
import { ArtworkScreeningAllergenWithWt } from 'types/brand/artworkScreening/artworkScreening';

interface ArtworkScreeningAllergensForm {
  allergens: ArtworkScreeningAllergenWithWt[];
}

const AllergenInputModalContent = ({
  onClose,
  onOk,
  allergens,
}: {
  onClose: () => void;
  onOk: (formValues: ArtworkScreeningAllergensForm) => void;
  allergens: ArtworkScreeningAllergenWithWt[];
}) => {
  const [form] = Form.useForm<ArtworkScreeningAllergensForm>();
  const { artworkScreeningAllergens, getArtworkScreeningAllergensLoading } =
    useArtworkScreeningAllergens();

  const handleSubmit = (formValues: ArtworkScreeningAllergensForm) => {
    onOk(formValues);
    onClose();
  };

  useEffect(() => {
    const allergensMap = allergens.reduce((acc, curr) => {
      acc[curr.ingredientAllergenId] = curr;
      return acc;
    }, {} as Record<number, ArtworkScreeningAllergenWithWt>);

    form.setFieldsValue({
      allergens: artworkScreeningAllergens.map(
        (item) => allergensMap[item.ingredientAllergenId] || item,
      ),
    });
  }, [artworkScreeningAllergens]);

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Spin spinning={getArtworkScreeningAllergensLoading}>
        <div style={{ height: 640, overflowY: 'auto' }}>
          <Form.List name="allergens" initialValue={[]}>
            {(fields) => (
              <StyledTable>
                <thead>
                  <tr>
                    <th style={{ width: 56 }}>No.</th>
                    <th style={{ width: 400 }}>{`INCI Name\n한글 성분명`}</th>
                    <th style={{ width: 184 }}>{`향료에서의\n함량비율(%)`}</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((field, idx) => (
                    <tr key={field.key}>
                      <td style={{ width: 56, textAlign: 'center' }}>{idx + 1}</td>
                      <td style={{ width: 400 }}>
                        <Typography.Text type="BODY_2">
                          {artworkScreeningAllergens[field.name].allergenInciName}
                        </Typography.Text>
                        <Typography.Text type="BODY_2" gutter={{ top: 2 }}>
                          {artworkScreeningAllergens[field.name].allergenNameKo}
                        </Typography.Text>
                      </td>
                      <td style={{ width: 184 }}>
                        <Form.Item
                          name={[field.name, 'allergenWt']}
                          rules={[
                            {
                              validator: async (_, value) => {
                                if (!value) return Promise.resolve();

                                if (isNaN(value)) {
                                  return Promise.reject('0~100 숫자만 입력 가능');
                                }

                                const numValue = Number(value);
                                if (numValue < 0 || numValue > 100) {
                                  return Promise.reject('0~100 숫자만 입력 가능');
                                }

                                if (!/^-?\d+(\.\d{1,25})?$/g.test(value)) {
                                  return Promise.reject('소수점 최대 25자리까지 가능');
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input placeholder="% 입력" />
                        </Form.Item>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            )}
          </Form.List>
        </div>
      </Spin>
      <Flex justify="center" align="center" gap={8} gutter={{ top: 24 }}>
        <Button key="back" onClick={onClose} style={{ width: 84 }}>
          건너뛰기
        </Button>
        <Button htmlType="submit" type="primary" style={{ width: 84 }}>
          입력 완료
        </Button>
      </Flex>
    </Form>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  border-top: 2px solid ${palette.PRIMARY50};
  text-align: left;
  table-layout: fixed;

  thead tr {
    border-bottom: 1px solid ${palette.GRAY40};
    background-color: ${palette.GRAY10};
    height: 56px;

    th {
      padding: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.6px;
      color: ${palette.SLATE_GRAY70};

      &:first-of-type {
        padding-left: 16px;
      }
      &:last-of-type {
        padding-right: 16px;
      }
    }
  }

  tbody tr {
    height: 64px;
    border-bottom: 1px solid ${palette.GRAY40};

    td {
      padding: 10px;

      &:first-of-type {
        padding-left: 16px;
      }
      &:last-of-type {
        padding-right: 16px;
      }
    }
  }

  .ant-form-item .ant-form-item-explain {
    height: 0;
    min-height: 0;
    transform: translateY(-2px);
  }
`;

export default AllergenInputModalContent;
