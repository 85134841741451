import { Button, FormInstance, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import { UseMutateFunction } from 'react-query';
import styled, { css } from 'styled-components';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import palette from 'lib/styles/palette';
import { ScreeningFormData } from 'pages/brand/formulaScreening/FormulaScreeningPage';
import {
  CountryLaw,
  FormulaScreeningItem,
  FormulaScreeningJson,
  Regulation,
} from 'service/brand/formulaScreening/formulaScreening';
import { APIResponse } from 'types/common';

const Step2 = ({
  file,
  form,
  method,
  setStep,
  selectedCountryLaws,
  selectedDistributorIds,
  selectedSpecialRegionIds,
  setSelectedCountryLaws,
  setSelectedSpecialRegionIds,
  setSelectedDistributorIds,
  uploadFormulaScreeningJson,
  uploadFormulaScreening,
  uploadLoading,
  uploadFormulaScreeningJsonLoading,
  allRegulations,
  allRegulationsLoading,
}: {
  allRegulations: Regulation | undefined;
  allRegulationsLoading: boolean;
  file: File | null;
  method: number;
  form: FormInstance<ScreeningFormData>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  selectedCountryLaws: CountryLaw[];
  selectedSpecialRegionIds: string[];
  selectedDistributorIds: string[];
  setSelectedCountryLaws: React.Dispatch<React.SetStateAction<CountryLaw[]>>;
  setSelectedSpecialRegionIds: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedDistributorIds: React.Dispatch<React.SetStateAction<string[]>>;
  uploadFormulaScreeningJson: UseMutateFunction<
    AxiosResponse<APIResponse<FormulaScreeningItem>>,
    unknown,
    FormulaScreeningJson,
    unknown
  >;
  uploadFormulaScreening: UseMutateFunction<
    AxiosResponse<APIResponse<FormulaScreeningItem>>,
    unknown,
    FormData,
    unknown
  >;
  uploadLoading: boolean;
  uploadFormulaScreeningJsonLoading: boolean;
}) => {
  const countryRefs = useRef<(HTMLDivElement | null)[]>([]);
  const distributorRefs = useRef<(HTMLDivElement | null)[]>([]);
  const specialRegionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const specialRegionNameRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [showCountryTooltips, setShowCountryTooltips] = useState(
    Array(allRegulations?.countryLaws?.length || 0).fill(false),
  );
  const [showDistributorTooltips, setShowDistributorTooltips] = useState(
    Array(allRegulations?.distributorLaws?.length || 0).fill(false),
  );
  const [showSpecialRegionTooltips, setShowSpecialRegionTooltips] = useState(
    Array(allRegulations?.specialRegionLaws?.length || 0).fill(false),
  );
  const [showSpecialRegionNameTooltips, setShowSpecialRegionNameTooltips] = useState(
    Array(allRegulations?.specialRegionLaws?.length || 0).fill(false),
  );

  const countries = useMemo(() => {
    return allRegulations?.countryLaws;
  }, [allRegulations]);

  const specialRegions = useMemo(() => {
    return allRegulations?.specialRegionLaws;
  }, [allRegulations]);

  const distributors = useMemo(() => {
    return allRegulations?.distributorLaws;
  }, [allRegulations]);

  const disabledMode =
    selectedCountryLaws.length === 0 &&
    selectedDistributorIds.length === 0 &&
    selectedSpecialRegionIds.length === 0;

  useEffect(() => {
    const tooltipCountryVisibility = countryRefs.current.map((ref) => {
      if (ref) {
        return ref.scrollWidth > ref.offsetWidth;
      }
      return false;
    });
    setShowCountryTooltips(tooltipCountryVisibility);

    const tooltipDistributorVisibility = distributorRefs.current.map((ref) => {
      if (ref) {
        return ref.scrollWidth > ref.offsetWidth;
      }
      return false;
    });
    setShowDistributorTooltips(tooltipDistributorVisibility);

    const tooltipSpecialRegionVisibility = specialRegionRefs.current.map((ref) => {
      if (ref) {
        return ref.scrollWidth > ref.offsetWidth;
      }
      return false;
    });
    setShowSpecialRegionTooltips(tooltipSpecialRegionVisibility);
    const tooltipSpecialRegionNameVisibility = specialRegionNameRefs.current.map((ref) => {
      if (ref) {
        return ref.scrollWidth > ref.offsetWidth;
      }
      return false;
    });
    setShowSpecialRegionNameTooltips(tooltipSpecialRegionNameVisibility);
  }, [allRegulations]);

  const handleShowResult = () => {
    if (method === 1) {
      const ingredients: ScreeningFormData['ingredients'] = form.getFieldValue('ingredients');

      const filteredIngredients = ingredients.filter(
        ({ name }) => name && name !== null && name !== '',
      );

      uploadFormulaScreeningJson(
        {
          ingredients: filteredIngredients.map((item) => ({
            ...item,
            name: item.name.trimEnd(),
            wt: item.wt,
          })),
          ...(selectedCountryLaws.length > 0 && {
            countryLaws: selectedCountryLaws.map(({ countryId, lawId }) => ({
              countryId,
              lawId,
            })),
          }),
          ...(selectedDistributorIds.length > 0 && {
            distributorLawIds: selectedDistributorIds.map((code) => Number(code.split('_')[1])),
          }),
          ...(selectedSpecialRegionIds.length > 0 && {
            specialRegionLawIds: selectedSpecialRegionIds.map((code) => Number(code.split('_')[1])),
          }),
        },
        {
          onSuccess: (res) => {
            const { list } = res.data.result;

            if (list?.length !== 0) {
              setStep(3);
            }
          },
        },
      );
    } else {
      if (!file) return;

      const formData = new FormData();
      formData.append('uploadFile', file);
      selectedCountryLaws.forEach(({ countryId, lawId }, idx) => {
        formData.append(`countryLaws[${idx}].countryId`, `${countryId}`);
        formData.append(`countryLaws[${idx}].lawId`, `${lawId}`);
      });
      selectedDistributorIds.forEach((id) => {
        formData.append(`distributorLawIds`, id.split('_')[1]);
      });
      selectedSpecialRegionIds.forEach((id) => {
        formData.append(`specialRegionLawIds`, id.split('_')[1]);
      });

      uploadFormulaScreening(formData, {
        onSuccess: (res) => {
          if (res.data.result) setStep(3);
        },
      });
    }
  };

  return (
    <Container>
      <LoadingModal
        visible={uploadFormulaScreeningJsonLoading || uploadLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 스크리닝 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <Spin spinning={allRegulationsLoading}>
        <Flex justify="space-between" align="center">
          <HeaderText>국가 선택</HeaderText>
          <Flex
            align="center"
            style={{ cursor: 'pointer' }}
            gap={8}
            onClick={() => {
              if (selectedCountryLaws.length === countries?.length) {
                setSelectedCountryLaws([]);
                return;
              }
              const newArr: CountryLaw[] = [];
              countries?.forEach(({ country, law }) => {
                newArr.push({
                  countryId: country.countryId,
                  lawId: law.lawId,
                });
              });

              setSelectedCountryLaws(newArr);
            }}
          >
            <Icon
              name="check"
              size={18}
              color={selectedCountryLaws.length === countries?.length ? 'PRIMARY50' : 'GRAY50'}
            />
            <Typography.Text
              type="BODY_2"
              style={{
                color:
                  selectedCountryLaws.length === countries?.length
                    ? palette.PRIMARY50
                    : palette.GRAY50,
              }}
            >
              전체 선택
            </Typography.Text>
          </Flex>
        </Flex>
        <GridContainer>
          {countries
            ?.sort((a, b) => (a.country.nameKo < b.country.nameKo ? -1 : 1))
            .map(({ country, countryLawId, confederationLawId, law }, index) => {
              const isActive = selectedCountryLaws.some(
                ({ countryId, lawId }) => countryId === country.countryId && lawId === law.lawId,
              );

              return (
                <SelectItem
                  key={`${country.countryId}_countryLawId${countryLawId}_confederationLawId${confederationLawId}`}
                  align="center"
                  gap={8}
                  onClick={() => {
                    if (isActive) {
                      setSelectedCountryLaws((prev) =>
                        prev.filter(
                          ({ countryId, lawId }) =>
                            countryId !== country.countryId || lawId !== law.lawId,
                        ),
                      );
                    } else {
                      setSelectedCountryLaws((prev) =>
                        prev.concat({
                          countryId: country.countryId,
                          lawId: law.lawId,
                        }),
                      );
                    }
                  }}
                  isActive={isActive}
                >
                  <img
                    src={country.logoUrl}
                    alt={country.nameKo}
                    width={28}
                    height={28}
                    style={{ borderRadius: 100 }}
                  />
                  <Flex dir="column" gap={4}>
                    <Typography.Text
                      type="BODY_2"
                      style={{
                        color: palette.GRAY90,
                        maxWidth: 222,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {country.nameKo}
                    </Typography.Text>
                    <RegulationNamDiv
                      key={index}
                      index={index}
                      overflowed={showCountryTooltips[index]}
                      ref={(el) => {
                        countryRefs.current[index] = el;
                      }}
                    >
                      {law.name}
                      <ShowTooltipDiv
                        className={`tooltip_${index}`}
                        justify="center"
                        dir="column"
                        align="center"
                      >
                        {law.name}
                      </ShowTooltipDiv>
                    </RegulationNamDiv>
                  </Flex>
                </SelectItem>
              );
            })}
        </GridContainer>
        <Flex justify="space-between" align="center" gutter={{ top: 40 }}>
          <HeaderText>특수 지역 선택</HeaderText>
          <Flex
            align="center"
            style={{ cursor: 'pointer' }}
            gap={8}
            onClick={() => {
              if (selectedSpecialRegionIds.length === specialRegions?.length) {
                setSelectedSpecialRegionIds([]);
                return;
              }
              const newArr: string[] = [];
              specialRegions?.forEach(({ specialRegion, law, specialRegionLawId }) => {
                newArr.push(`${specialRegion.specialRegionId}_${specialRegionLawId}`);
              });

              setSelectedSpecialRegionIds(newArr);
            }}
          >
            <Icon
              name="check"
              size={18}
              color={
                selectedSpecialRegionIds.length === specialRegions?.length ? 'PRIMARY50' : 'GRAY50'
              }
            />
            <Typography.Text
              type="BODY_2"
              style={{
                color:
                  selectedSpecialRegionIds.length === specialRegions?.length
                    ? palette.PRIMARY50
                    : palette.GRAY50,
              }}
            >
              전체 선택
            </Typography.Text>
          </Flex>
        </Flex>
        <GridContainer>
          {specialRegions?.map(({ specialRegion, law, specialRegionLawId }, index) => {
            const isActive = selectedSpecialRegionIds.includes(
              `${specialRegion.specialRegionId}_${specialRegionLawId}`,
            );

            return (
              <SelectItem
                isActive={isActive}
                key={`${specialRegion.specialRegionId}_${specialRegionLawId}`}
                align="center"
                gap={8}
                onClick={() => {
                  if (isActive) {
                    setSelectedSpecialRegionIds((prev) =>
                      prev.filter(
                        (id) => id !== `${specialRegion.specialRegionId}_${specialRegionLawId}`,
                      ),
                    );
                  } else {
                    setSelectedSpecialRegionIds((prev) =>
                      prev.concat(`${specialRegion.specialRegionId}_${specialRegionLawId}`),
                    );
                  }
                }}
              >
                <img
                  src={specialRegion.logoUrl}
                  alt={specialRegion.specialRegionName}
                  width={28}
                  height={28}
                  style={{ borderRadius: 100 }}
                />
                <Flex dir="column" gap={4}>
                  <RegulationNamDiv
                    style={{ color: palette.GRAY90 }}
                    key={index}
                    index={index}
                    overflowed={showSpecialRegionNameTooltips[index]}
                    ref={(el) => {
                      specialRegionNameRefs.current[index] = el;
                    }}
                  >
                    {specialRegion.specialRegionName}
                    <ShowTooltipDiv
                      className={`tooltip_${index}`}
                      justify="center"
                      dir="column"
                      align="center"
                      style={{ top: 32 }}
                    >
                      {specialRegion.specialRegionName}
                    </ShowTooltipDiv>
                  </RegulationNamDiv>

                  <RegulationNamDiv
                    key={index}
                    index={index}
                    overflowed={showSpecialRegionTooltips[index]}
                    ref={(el) => {
                      specialRegionRefs.current[index] = el;
                    }}
                  >
                    {law.name}
                    <ShowTooltipDiv
                      className={`tooltip_${index}`}
                      justify="center"
                      dir="column"
                      align="center"
                    >
                      {law.name}
                    </ShowTooltipDiv>
                  </RegulationNamDiv>
                </Flex>
              </SelectItem>
            );
          })}
        </GridContainer>
        <Flex justify="space-between" align="center" gutter={{ top: 40 }}>
          <HeaderText>스토어 선택</HeaderText>
          <Flex
            align="center"
            style={{ cursor: 'pointer' }}
            gap={8}
            onClick={() => {
              if (selectedDistributorIds.length === distributors?.length) {
                setSelectedDistributorIds([]);
                return;
              }
              const newArr: string[] = [];
              distributors?.forEach(({ distributor, law, distributorLawId }) => {
                newArr.push(`${distributor.distributorId}_${distributorLawId}`);
              });

              setSelectedDistributorIds(newArr);
            }}
          >
            <Icon
              name="check"
              size={18}
              color={
                selectedDistributorIds.length === distributors?.length ? 'PRIMARY50' : 'GRAY50'
              }
            />
            <Typography.Text
              type="BODY_2"
              style={{
                color:
                  selectedDistributorIds.length === distributors?.length
                    ? palette.PRIMARY50
                    : palette.GRAY50,
              }}
            >
              전체 선택
            </Typography.Text>
          </Flex>
        </Flex>
        <GridContainer>
          {distributors
            ?.sort(
              ({ distributor: { distributorName: a } }, { distributor: { distributorName: b } }) =>
                a.localeCompare(b),
            )
            .map(({ distributor, law, distributorLawId }, index) => {
              const isActive = selectedDistributorIds.includes(
                `${distributor.distributorId}_${distributorLawId}`,
              );

              return (
                <SelectItem
                  isActive={isActive}
                  key={`${distributor.distributorId}_${distributorLawId}`}
                  align="center"
                  gap={8}
                  onClick={() => {
                    if (isActive) {
                      setSelectedDistributorIds((prev) =>
                        prev.filter(
                          (id) => id !== `${distributor.distributorId}_${distributorLawId}`,
                        ),
                      );
                    } else {
                      setSelectedDistributorIds((prev) =>
                        prev.concat(`${distributor.distributorId}_${distributorLawId}`),
                      );
                    }
                  }}
                >
                  <img
                    width={28}
                    height={28}
                    src={distributor.logoUrl}
                    alt={distributor.distributorName}
                    style={{ borderRadius: 100 }}
                  />
                  <Flex dir="column" gap={4}>
                    <Typography.Text
                      type="BODY_2"
                      style={{
                        color: palette.GRAY90,
                        maxWidth: 222,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {distributor.distributorName}
                    </Typography.Text>
                    <RegulationNamDiv
                      key={index}
                      index={index}
                      overflowed={showDistributorTooltips[index]}
                      ref={(el) => {
                        distributorRefs.current[index] = el;
                      }}
                    >
                      {law.name}
                      <ShowTooltipDiv
                        className={`tooltip_${index}`}
                        justify="center"
                        dir="column"
                        align="center"
                      >
                        {law.name}
                      </ShowTooltipDiv>
                    </RegulationNamDiv>
                  </Flex>
                </SelectItem>
              );
            })}
        </GridContainer>
      </Spin>

      <Flex gap={16} style={{ marginTop: 56 }} justify="center">
        <Button
          htmlType="button"
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          type="default"
          onClick={() => setStep(1)}
        >
          이전
        </Button>
        <Button
          style={{
            width: 200,
            height: 56,
            background: disabledMode ? palette.SLATE_GRAY30 : palette.PRIMARY50,
            fontSize: 18,
          }}
          htmlType="button"
          type="primary"
          onClick={handleShowResult}
          disabled={disabledMode}
        >
          다음
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-top: 56px;
`;

const GridContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  grid-auto-columns: minmax(254px, auto);
`;

const SelectItem = styled(Flex)<{ isActive: boolean }>`
  position: relative;
  padding: 16px;
  align-items: center;
  justify-content: start;
  border: ${({ isActive }) =>
    isActive ? `1px solid ${palette.PRIMARY50}` : `1px solid ${palette.GRAY30}`};
  border-radius: 8px;
  width: 254px;
  height: 76px;
  cursor: pointer;
  gap: 16px;
  transition: border 300ms ease;
  overflow: visible;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${palette.PRIMARY10};
    `}
  &:hover {
    border: 1px solid ${palette.PRIMARY50};
  }
`;

const HeaderText = styled(Typography.Text)`
  font-size: 24px;
  color: ${palette.GRAY90};
`;

const ShowTooltipDiv = styled(Flex)`
  position: absolute;
  width: max-content;
  background-color: ${palette.GRAY80};
  border-radius: 4px;
  opacity: 0;
  color: ${palette.ETC_WHITE};
  font-size: 10px;
  padding: 4px 6px;
  top: 60px;
  z-index: 10;
  transition: all 0.4s ease;
  flex-wrap: nowrap;
  display: flex;
  max-width: 480px;
  word-wrap: break-word;
  white-space: normal;

  .text {
    color: ${palette.ETC_WHITE};
    font-size: 10px;
  }
`;

const RegulationNamDiv = styled.div<{ index: number; overflowed: boolean }>`
  text-overflow: ellipsis;
  max-width: 178px;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: ${palette.GRAY70};
  cursor: pointer;

  &:hover {
    ${({ index, overflowed }) =>
      index &&
      overflowed &&
      css`
        .tooltip_${index} {
          opacity: 1;
        }
      `}
  }
`;

export default Step2;
