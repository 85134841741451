import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Input, message } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { cloneDeep } from 'lodash';

import palette from 'lib/styles/palette';
import { Flex } from 'components/ui';
import { Typography } from 'components/system';
import { getTextColorByRule, initialInquiry } from 'lib/artworkScreening';
import Icon from 'components/ui/Icon/Icon';
import {
  ArtworkPreIngredient,
  ArtworkPostIngredient,
  RuleType,
  ArtworkIngredient,
} from 'types/brand/artworkScreening/artworkScreening';
import TooltipChip from './TooltipChip';
import { useModal } from 'hook/useModal';
import CountryModalContent from './CountryModalContent';
import TooltipIcon from './TooltipIcon';
import { useFormulaScreeningArtworkInquiry } from 'service/brand/artworkScreening/artworkScreening';

type ArtworkIngredientListItemProps = ArtworkIngredient & {
  idx: number;
  formulaScreeningId: number;
  openCollapseId?: number;
  setOpenCollapseId: Dispatch<SetStateAction<number | undefined>>;
  textRect?: DOMRect;
  setTextRect: Dispatch<SetStateAction<DOMRect | undefined>>;
  targetRect?: DOMRect;
  setTargetRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  setClickedIngredientArea: Dispatch<
    SetStateAction<{
      type?: 'formulaScreeningAnalyzeItemId' | 'formulaScreeningArtworkImageIngredientId';
      id?: number;
    }>
  >;
  setArtworkIngredients: Dispatch<SetStateAction<ArtworkIngredient[]>>;
  selectedCountryCodes: string[];
  artworkIngredients: ArtworkIngredient[];
  handleReport: (temp: ArtworkIngredient[]) => void;
};

const ArtworkIngredientListItem = ({
  coordinates,
  errors,
  idx,
  openCollapseId,
  setOpenCollapseId,
  textRect,
  setTextRect,
  targetRect,
  setTargetRect,
  setClickedIngredientArea,
  setArtworkIngredients,
  selectedCountryCodes,
  artworkIngredients,
  handleReport,
  allergen,
  ...ingredientListItem
}: ArtworkIngredientListItemProps) => {
  const {
    id,
    imageIngredient,
    formulaScreeningId,
    name,
    newDirectName: ingredientNewDirectName,
  } = ingredientListItem;
  const isOpen = openCollapseId === id;

  /**
   * isHover: 마우스 올렸을 때 테두리 표시용
   * directName: 직접 이름 입력 값
   * inquiry: 의견 남기기 관련 상태
   */
  const [isHover, setIsHover] = useState(false);
  const [newDirectName, setNewDirectName] = useState<Record<RuleType, string>>(
    errors.reduce((acc, { ruleType }) => {
      acc[ruleType] = '';
      return acc;
    }, {} as Record<RuleType, string>),
  );
  const [inquiry, setInquiry] =
    useState<Record<RuleType, { isOpen: boolean; content: string }>>(initialInquiry);

  // 의견 남기기 부분 textarea 포커스를 위한 ref. (실제 textarea 부분이 작아서 전체 영역이 textarea처럼 동작할 수 있도록)
  const textareaRef = useRef<Map<RuleType, TextAreaRef>>(new Map());
  const { openConfirmModal } = useModal();
  const { registerFormulaScreeningArtworkInquiry } = useFormulaScreeningArtworkInquiry();

  // 헤더 클릭시 id 저장
  const handleClickHeader = () => {
    setClickedIngredientArea(
      isOpen
        ? {}
        : {
            type: imageIngredient
              ? 'formulaScreeningArtworkImageIngredientId'
              : 'formulaScreeningAnalyzeItemId',
            id: imageIngredient ? imageIngredient.formulaScreeningArtworkImageIngredientId : id,
          },
    );
  };

  // 적용하기 및 취소
  const handleToggleApply = ({
    isCancel = false,
    ruleType,
    preIngredient,
    postIngredient,
    errorIdx,
    directName,
  }: {
    isCancel?: boolean;
    ruleType: RuleType;
    preIngredient: ArtworkPreIngredient;
    postIngredient: ArtworkPostIngredient | null;
    errorIdx: number;
    directName?: string;
  }) => {
    // 적용을 눌렀을 때 해당 성분 말고 묶여있는 모든 성분이 적용이 되어야 하기 때문에 해당 목록을 가지고 옴
    const ids = preIngredient.center.map((item) => item.id);

    const newUpdatedErrors = errors.map((error, idx) => {
      if (idx !== errorIdx) {
        return error;
      }
      return {
        ...error,
        isUpdate: !isCancel,
      };
    });

    const newArtworkIngredients = cloneDeep(artworkIngredients);
    const finalData = newArtworkIngredients.map((ingredient, idx) => {
      if (ids.includes(ingredient.id)) {
        if (ruleType === RuleType.TYPO) {
          //TYPO TYPE
          const newUpdatedErrors = ingredient.errors.map((error, idx) => {
            if (ruleType !== error.ruleType) {
              return error;
            }
            return {
              ...error,
              isUpdate: !isCancel,
            };
          });
          ingredient.updatedName = isCancel
            ? ''
            : postIngredient?.center.map(({ name }) => name).join(', ') || '';

          if (directName && directName !== '') {
            ingredient.updatedName = '';
            ingredient.newDirectName = ingredient.errors.reduce((acc, { ruleType: type }) => {
              if (type === ruleType) {
                acc[type] = isCancel ? '' : directName;
              } else {
                acc[type] = '';
              }
              return acc;
            }, {} as Record<RuleType, string>);
          } else {
            ingredient.newDirectName = ingredient.errors.reduce((acc, { ruleType: type }) => {
              if (type === ruleType) {
                acc[type] = '';
              }
              return acc;
            }, {} as Record<RuleType, string>);
          }

          return {
            ...ingredient,
            errors: newUpdatedErrors,
          };
        } else if (ruleType === RuleType.ORDER) {
          //ORDER TYPE
          const newUpdatedErrors = ingredient.errors.map((error, idx) => {
            if (ruleType !== error.ruleType) {
              return error;
            }
            return {
              ...error,
              isUpdate: !isCancel,
            };
          });
          const preIds = preIngredient?.center.map(({ id }) => id) || [];
          const postIds = postIngredient?.center.map(({ id }) => id) || [];

          const orderMap: Record<number, number> = {};

          preIds.forEach((id) => {
            const index = postIds.indexOf(id);
            const ingredient = artworkIngredients.find((item) => item.id === preIds[index]);

            orderMap[id] = ingredient?.order || 0;
          });

          return {
            ...ingredient,
            errors: newUpdatedErrors,
            order: isCancel ? ingredient.initialOrder : orderMap[ingredient.id],
          };
        } else if (ruleType === RuleType.MISS) {
          //MISS TYPE
          const newUpdatedErrors = ingredient.errors.map((error, idx) => {
            if (ruleType !== error.ruleType) {
              return error;
            }

            return {
              ...error,
              isUpdate: !isCancel,
            };
          });

          //pre안에 children으로 넣어주기
          const preTarget = artworkIngredients.findIndex(({ id }) => id === postIngredient?.pre.id);
          const postTarget = artworkIngredients.findIndex(
            ({ id }) => id === postIngredient?.post.id,
          );
          const hasUpdatedCheckError = newArtworkIngredients[preTarget + 1].errors.find(
            (error) => error.ruleType === RuleType.CHECK && error.isUpdate,
          );

          if (allergen) {
            const postChildren =
              postIngredient?.center.map(
                (item) => artworkIngredients.find(({ id }) => item.id === id)!,
              ) || [];
            if (preTarget !== -1) {
              newArtworkIngredients[preTarget].postChildren = isCancel ? [] : postChildren;
            }

            return {
              ...ingredient,
              errors: newUpdatedErrors,
            };
          }

          if (preTarget !== -1) {
            const postChildren =
              postIngredient?.center.map(
                (item) => artworkIngredients.find(({ id }) => item.id === id)!,
              ) || [];
            const getPostChildren = () => {
              if (isCancel) {
                if (hasUpdatedCheckError) {
                  ingredient.updatedName =
                    hasUpdatedCheckError.postIngredient?.center
                      .map(({ name }) => name)
                      .join(', ') || '';
                  return newArtworkIngredients[preTarget].postChildren.map((item) => ({
                    ...item,
                    updatedName:
                      hasUpdatedCheckError.postIngredient?.center
                        .map(({ name }) => name)
                        .join(', ') || '',
                  }));
                } else {
                  ingredient.updatedName = '';
                  return [];
                }
              } else {
                if (hasUpdatedCheckError) {
                  ingredient.newDirectName = ingredient.errors.reduce((acc, { ruleType: type }) => {
                    acc[RuleType.CHECK] = '';
                    return acc;
                  }, {} as Record<RuleType, string>);
                  ingredient.updatedName =
                    postIngredient?.center.map(({ name }) => name).join(', ') || '';

                  return newArtworkIngredients[preTarget].postChildren.map((item) => ({
                    ...item,
                    updatedName: postIngredient?.center.map(({ name }) => name).join(', ') || '',
                  }));
                } else {
                  return postChildren;
                }
              }
            };

            newArtworkIngredients[preTarget].postChildren = getPostChildren();

            ingredient.currUpdatedRuleType = ruleType;
          } else if (postTarget !== -1) {
            const preChildren =
              postIngredient?.center.map(
                (item) => artworkIngredients.find(({ id }) => item.id === id)!,
              ) || [];
            newArtworkIngredients[postTarget].preChildren = isCancel ? [] : preChildren;
            ingredient.currUpdatedRuleType = ruleType;
          }

          ingredient.newDirectName[ruleType] = '';

          return {
            ...ingredient,
            errors: newUpdatedErrors,
          };
        } else if (ruleType === RuleType.CHECK) {
          //CHECK TYPE
          const newUpdatedErrors = ingredient.errors.map((error, idx) => {
            if (ruleType !== error.ruleType) {
              return error;
            }
            return {
              ...error,
              isUpdate: !isCancel,
            };
          });
          const preTarget = artworkIngredients.findIndex(({ id }) => id === postIngredient?.pre.id);
          const hasUpdatedTypoError = ingredient.errors.find(
            ({ ruleType, isUpdate }) => ruleType === RuleType.TYPO && isUpdate,
          );

          if (preTarget !== -1) {
            const hasUpdatedMissError = ingredient?.errors.find(
              (error) => error.ruleType === RuleType.MISS && error.isUpdate,
            );
            const postChildren =
              postIngredient?.center.map(
                (item) => artworkIngredients.find(({ id }) => item.id === id)!,
              ) || [];
            const getPostChildren = () => {
              if (isCancel) {
                if (hasUpdatedMissError) {
                  ingredient.updatedName =
                    hasUpdatedMissError.postIngredient?.center.map(({ name }) => name).join(', ') ||
                    '';

                  return newArtworkIngredients[preTarget].postChildren.map((item) => ({
                    ...item,
                    updatedName: newArtworkIngredients[preTarget].updatedName,
                  }));
                } else {
                  return [];
                }
              } else {
                if (hasUpdatedMissError) {
                  return newArtworkIngredients[preTarget].postChildren.map((item) => ({
                    ...item,
                    updatedName: newArtworkIngredients[preTarget].updatedName,
                  }));
                } else {
                  return postChildren.map((item) => ({
                    ...item,
                    updatedName: getPreTargetUpdateName(),
                  }));
                }
              }
            };
            const getPreTargetUpdateName = () => {
              if (!isCancel && !hasUpdatedMissError) {
                return postIngredient?.center.map(({ name }) => name).join(', ') || '';
              }
              return '';
            };

            newArtworkIngredients[preTarget].postChildren = getPostChildren();
          }

          ingredient.updatedName = isCancel
            ? hasUpdatedTypoError
              ? hasUpdatedTypoError?.postIngredient?.center.map(({ name }) => name).join(', ') || ''
              : ''
            : postIngredient?.center.map(({ name }) => name).join(', ') || '';

          if (directName && directName !== '') {
            ingredient.updatedName = '';

            ingredient.newDirectName = ingredient.errors.reduce((acc, { ruleType: type }) => {
              if (type === ruleType) {
                acc[type] = isCancel ? '' : directName;
              } else {
                acc[type] = '';
              }
              return acc;
            }, {} as Record<RuleType, string>);
          } else {
            ingredient.newDirectName = ingredient.errors.reduce((acc, { ruleType: type }) => {
              if (type === ruleType) {
                acc[type] = '';
              }
              return acc;
            }, {} as Record<RuleType, string>);
          }

          ingredient.currUpdatedRuleType = ruleType;

          return {
            ...ingredient,
            errors: newUpdatedErrors,
          };
        }
        ingredient.currUpdatedRuleType = ruleType;

        return {
          ...ingredient,
          errors: newUpdatedErrors,
        };
      }

      return ingredient;
    });

    setArtworkIngredients(finalData);
    handleReport(finalData);

    message.success(isCancel ? '취소되었습니다.' : '적용되었습니다.');
  };

  // 의견 남기기
  const handleSubmitInquiry = (ruleType: RuleType) => {
    if (!inquiry[ruleType].content) {
      message.warning('입력한 내용이 없습니다.');
      return;
    }

    if (inquiry[ruleType].content.length > 1000) {
      message.warning('최대 1,000자까지 입력 가능합니다.');
      return;
    }
    registerFormulaScreeningArtworkInquiry(
      {
        formulaScreeningId,
        content: inquiry[ruleType].content,
        formulaScreeningAnalyzeItemId: id,
      },
      {
        onSuccess: () => {
          message.success('제출되었습니다. 담당자가 검토 후 메일로 전달드립니다.');
          setInquiry({
            ...inquiry,
            [ruleType]: {
              isOpen: false,
              content: '',
            },
          });
        },
      },
    );
  };

  // 국가 변경시 초기화
  useEffect(() => {
    setNewDirectName(
      errors.reduce((acc, { ruleType }) => {
        acc[ruleType] = '';
        return acc;
      }, {} as Record<RuleType, string>),
    );
    setInquiry(initialInquiry);
  }, [selectedCountryCodes]);

  // 오류 없을 시
  if (errors.length === 0) {
    return (
      <Container>
        <Header idx={idx} onClick={handleClickHeader}>
          <Typography.Text color="SLATE_GRAY70">{name}</Typography.Text>
        </Header>
      </Container>
    );
  }

  return (
    <Container>
      {/* 헤더 */}
      <Header
        justify={isHover ? 'space-between' : 'flex-start'}
        idx={idx}
        isHover={isHover}
        onClick={() => {
          setOpenCollapseId(isOpen ? undefined : id);
          handleClickHeader();
        }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {/* 첫번째 에러가 우선순위가 가장 높기에 해당 색깔 표시 */}
        <Typography.Text color={getTextColorByRule(errors[0].ruleType)}>{name}</Typography.Text>
        <Icon
          name={isOpen ? 'up' : 'down'}
          color="SLATE_GRAY70"
          size={18}
          style={{
            flex: '0 0 18px',
          }}
        />
        {isHover && <HoveredBorder />}
      </Header>
      {/* 패널 */}
      {isOpen && (
        <Panel>
          {errors.map(
            (
              {
                ruleType,
                preIngredient,
                postIngredient,
                descriptions,
                countryRules,
                isDuplicate,
                isUpdate,
              },
              errorIdx,
            ) => {
              const color = getTextColorByRule(ruleType);
              const postCenterNames = postIngredient?.center.filter(
                ({ name }) => name && name !== '',
              );
              const isCheckError = ruleType === RuleType.CHECK;

              const isSingleCountryMatch =
                selectedCountryCodes.length === 1 &&
                countryRules.every(({ countryCodes }) =>
                  countryCodes.includes(selectedCountryCodes[0]),
                );

              const hasNoChip = isCheckError
                ? !isSingleCountryMatch ||
                  (!postIngredient?.pre.name &&
                    postCenterNames?.length === 0 &&
                    !postIngredient?.post.name)
                : !postIngredient?.pre.name &&
                  postCenterNames?.length === 0 &&
                  !postIngredient?.post.name;

              const matchedDirectName = newDirectName[ruleType];

              const hasDirectName =
                ingredientNewDirectName[ruleType] && ingredientNewDirectName[ruleType] !== '';

              return (
                <ErrorItem key={ruleType}>
                  {/* 변경 전 */}
                  <Typography.Text color={color}>
                    {preIngredient.pre.name && (
                      <Typography.Text color="SLATE_GRAY70" inline>
                        {preIngredient.pre.name},{' '}
                      </Typography.Text>
                    )}
                    <Typography.Text color={color} inline>
                      {preIngredient.center.map(({ name }) => name).join(', ')}
                      {postIngredient?.post.name && ', '}
                    </Typography.Text>
                    {postIngredient?.post.name && (
                      <Typography.Text color="SLATE_GRAY70" inline>
                        {postIngredient.post.name}
                      </Typography.Text>
                    )}
                  </Typography.Text>
                  <Flex gutter={{ top: 8 }} gap={8} style={{ alignItems: 'flex-start' }}>
                    {!hasNoChip && (
                      <Icon name="front" size={18} color="SLATE_GRAY70" style={{ marginTop: 7 }} />
                    )}
                    <div>
                      {/* 변경 후 */}
                      <Flex gap={4} wrap="true" style={{ flex: 1 }}>
                        {!hasNoChip && postIngredient?.pre.name && (
                          <TooltipChip
                            ingredientName={postIngredient.pre.name}
                            color={palette.SLATE_GRAY50}
                            textRect={textRect}
                            setTextRect={setTextRect}
                          />
                        )}
                        {!isCheckError &&
                          postCenterNames?.map(({ name }, idx) => (
                            <TooltipChip
                              key={idx}
                              ingredientName={name}
                              color={palette[color]}
                              textRect={textRect}
                              setTextRect={setTextRect}
                            />
                          ))}
                        {isCheckError &&
                          isSingleCountryMatch &&
                          postCenterNames?.map(({ name }, idx) => (
                            <TooltipChip
                              key={idx}
                              ingredientName={name}
                              color={palette[color]}
                              textRect={textRect}
                              setTextRect={setTextRect}
                            />
                          ))}
                        {postIngredient?.post.name && (
                          <TooltipChip
                            ingredientName={postIngredient.post.name}
                            color={palette.SLATE_GRAY50}
                            textRect={textRect}
                            setTextRect={setTextRect}
                          />
                        )}
                      </Flex>
                      {/* 오류 설명 */}
                      <Flex
                        dir="column"
                        gutter={{ top: hasNoChip ? 0 : 12, left: hasNoChip ? 26 : 0 }}
                        gap={4}
                        style={{ alignItems: 'stretch' }}
                      >
                        {descriptions.map((description, idx) => (
                          <Flex gap={8} key={idx} style={{ maxWidth: 522 }}>
                            <Bullet />
                            <Typography.Text
                              color="SLATE_GRAY70"
                              medium
                              style={{ whiteSpace: 'pre-wrap', maxWidth: 510 }}
                            >
                              {description}
                            </Typography.Text>
                          </Flex>
                        ))}
                      </Flex>
                    </div>
                  </Flex>
                  <Flex
                    gutter={{ top: 12, left: 26 }}
                    justify="space-between"
                    style={{ padding: '2px 0', maxWidth: 522 }}
                  >
                    <Flex gap={8}>
                      {/* 국가별 규칙 보기 */}
                      {countryRules.length > 0 && (
                        <Typography.Text
                          color="PRIMARY50"
                          type="BODY_2"
                          hover
                          onClick={() => {
                            openConfirmModal({
                              title: '국가별 규칙 보기',
                              content: <CountryModalContent countryRules={countryRules} />,
                              footer: null,
                            });
                          }}
                        >
                          국가별 규칙 보기
                        </Typography.Text>
                      )}
                      {/* 의견 남기기 토글 */}
                      <TooltipIcon
                        name="textsms"
                        targetRect={targetRect}
                        setTargetRect={setTargetRect}
                        size={32}
                        icon={
                          <InquiryIconWrapper>
                            <Icon
                              name="textsms"
                              size={18}
                              color="SLATE_GRAY60"
                              hoverColor="SLATE_GRAY70"
                              style={{ width: 30, height: 30 }}
                            />
                          </InquiryIconWrapper>
                        }
                        onClick={() =>
                          setInquiry({
                            ...inquiry,
                            [ruleType]: {
                              ...inquiry[ruleType],
                              isOpen: !inquiry[ruleType].isOpen,
                            },
                          })
                        }
                      />
                    </Flex>
                    <Flex gap={4}>
                      {ruleType !== RuleType.CHECK &&
                        (isUpdate &&
                        !(
                          ruleType === RuleType.TYPO &&
                          ingredientListItem.newDirectName[ruleType] &&
                          ingredientListItem.newDirectName[ruleType] !== ''
                        ) ? (
                          <CancelButton
                            onClick={() =>
                              handleToggleApply({
                                isCancel: true,
                                ruleType,
                                preIngredient,
                                postIngredient,
                                errorIdx,
                              })
                            }
                          >
                            취소
                          </CancelButton>
                        ) : (
                          <ApplyButton
                            onClick={() =>
                              handleToggleApply({
                                isCancel: false,
                                ruleType,
                                preIngredient,
                                postIngredient,
                                errorIdx,
                              })
                            }
                          >
                            적용
                          </ApplyButton>
                        ))}
                      {ruleType === RuleType.CHECK &&
                        isSingleCountryMatch &&
                        (isUpdate &&
                        ((!ingredientListItem.newDirectName[ruleType] &&
                          ingredientListItem.newDirectName[ruleType] !== '') ||
                          ingredientListItem.updatedName !== '') ? (
                          <CancelButton
                            onClick={() =>
                              handleToggleApply({
                                isCancel: true,
                                ruleType,
                                preIngredient,
                                postIngredient,
                                errorIdx,
                              })
                            }
                          >
                            취소
                          </CancelButton>
                        ) : (
                          <ApplyButton
                            onClick={() =>
                              handleToggleApply({
                                isCancel: false,
                                ruleType,
                                preIngredient,
                                postIngredient,
                                errorIdx,
                              })
                            }
                          >
                            적용
                          </ApplyButton>
                        ))}
                    </Flex>
                  </Flex>
                  {(inquiry[ruleType].isOpen ||
                    ((ruleType === RuleType.TYPO || ruleType === RuleType.CHECK) &&
                      !isDuplicate)) && <HorizontalDivider />}
                  {inquiry[ruleType].isOpen ? (
                    <Flex style={{ alignItems: 'flex-start' }} gap={8}>
                      <Icon name="front" size={18} color="SLATE_GRAY70" style={{ marginTop: 13 }} />
                      <TextAreaWrapper
                        onClick={() => {
                          const targetRef = textareaRef.current.get(ruleType);
                          if (targetRef) targetRef.focus();
                        }}
                      >
                        <TextArea
                          autoSize
                          ref={(el) => {
                            if (el) {
                              textareaRef.current.set(ruleType, el);
                            } else {
                              textareaRef.current.delete(ruleType);
                            }
                          }}
                          placeholder="검토 결과에 대한 의견 입력"
                          value={inquiry[ruleType].content}
                          onChange={(e) =>
                            setInquiry({
                              ...inquiry,
                              [ruleType]: {
                                isOpen: true,
                                content: e.target.value,
                              },
                            })
                          }
                        />
                        <Flex justify="flex-end" gap={4} gutter={{ top: 8 }}>
                          <Typography.Text
                            type="BODY_2"
                            color="GRAY70"
                            style={{ padding: '2px 4px' }}
                            onClick={() =>
                              setInquiry({
                                ...inquiry,
                                [ruleType]: {
                                  ...inquiry[ruleType],
                                  isOpen: false,
                                },
                              })
                            }
                          >
                            닫기
                          </Typography.Text>
                          <Typography.Text
                            type="BODY_2"
                            color="PRIMARY50"
                            style={{ padding: '2px 4px' }}
                            onClick={() => handleSubmitInquiry(ruleType)}
                          >
                            제출
                          </Typography.Text>
                        </Flex>
                      </TextAreaWrapper>
                    </Flex>
                  ) : (
                    (ruleType === RuleType.TYPO || ruleType === RuleType.CHECK) &&
                    !isDuplicate && (
                      <Flex gap={8} style={{ alignItems: 'flex-start' }}>
                        <Icon
                          name="front"
                          size={18}
                          color="SLATE_GRAY70"
                          style={{ marginTop: 13 }}
                        />
                        <TextAreaWrapper
                          onClick={() => {
                            const targetRef = textareaRef.current.get(ruleType);
                            if (targetRef) targetRef.focus();
                          }}
                        >
                          <TextArea
                            autoSize
                            ref={(el) => {
                              if (el) {
                                textareaRef.current.set(ruleType, el);
                              } else {
                                textareaRef.current.delete(ruleType);
                              }
                            }}
                            value={matchedDirectName}
                            placeholder="성분명 직접 입력"
                            onChange={(e) => {
                              setNewDirectName((prev) => ({
                                ...prev,
                                [ruleType]: e.target.value,
                              }));
                            }}
                          />
                          <Flex justify="flex-end" gutter={{ top: 8 }}>
                            <Typography.Text
                              color={hasDirectName ? 'GRAY70' : 'PRIMARY50'}
                              type="BODY_2"
                              style={{ padding: '2px 4px' }}
                              onClick={() => {
                                if (!matchedDirectName) {
                                  message.warning('입력한 내용이 없습니다.');
                                  return;
                                }
                                if (matchedDirectName.length > 2000) {
                                  message.warning('최대 2,000자까지 입력 가능합니다.');
                                  return;
                                }
                                handleToggleApply({
                                  isCancel: !!hasDirectName,
                                  directName: newDirectName[ruleType],
                                  ruleType,
                                  preIngredient,
                                  postIngredient,
                                  errorIdx,
                                });
                              }}
                            >
                              {hasDirectName ? '취소' : '적용'}
                            </Typography.Text>
                          </Flex>
                        </TextAreaWrapper>
                      </Flex>
                    )
                  )}
                </ErrorItem>
              );
            },
          )}
        </Panel>
      )}
    </Container>
  );
};

const Container = styled.div``;

const Header = styled(Flex)<{ idx: number; isHover?: boolean }>`
  gap: 8px;
  margin-top: ${({ idx }) => (idx === 0 ? 0 : 16)}px;
  position: relative;
  width: ${({ isHover }) => (isHover ? `100%` : `580px`)};
  cursor: pointer;
`;

const Panel = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`;

const ErrorItem = styled.div`
  border-radius: 4px;
  padding: 16px;
  background: ${palette.SLATE_GRAY10};
  width: 100%;
`;

const HoveredBorder = styled.div`
  position: absolute;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  left: -16px;
  top: -12px;
  bottom: -12px;
  right: -16px;
`;

const HorizontalDivider = styled.div`
  height: 1px;
  background-color: ${palette.GRAY30};
  margin: 16px 0;
`;

const Bullet = styled.div`
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
  background-color: ${palette.SLATE_GRAY50};
  align-self: flex-start;
  margin-top: 9px;
`;

const InquiryIconWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${palette.GRAY30};
  background: #fff;
  cursor: pointer;
`;

const CancelButton = styled(Flex)`
  justify-content: center;
  height: 32px;
  width: 41px;
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: ${palette.GRAY70};
  cursor: pointer;
`;

const ApplyButton = styled(Button)`
  height: 32px;
  width: 41px;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextAreaWrapper = styled.div`
  flex: 1;
  padding: 11px 15px;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY40};
  background-color: #fff;
  transition: all 0.5s ease;
  cursor: text;

  &:hover,
  &:focus,
  &:active {
    border-color: #61a5ff;
    transition: all 0.5s ease;
  }
`;
const TextArea = styled(Input.TextArea)`
  border: none;
  padding: 0;
  line-height: 20px !important;
  min-height: 20px !important;
  max-height: 264px !important;
  resize: none;
`;

export default ArtworkIngredientListItem;
