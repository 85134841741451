import { useForm } from 'antd/lib/form/Form';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { message } from 'antd';
import { useSelector } from 'react-redux';

import ArtworkStep1 from 'components/brand/artworkScreening/step1/ArtworkStep1';
import ArtworkStep2 from 'components/brand/artworkScreening/step2/ArtworkStep2';
import ArtworkStep3 from 'components/brand/artworkScreening/step3/ArtworkStep3';
import ArtworkStep4 from 'components/brand/artworkScreening/step4/ArtworkStep4';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { ArtworkItem } from 'types/brand/product/artworkScreening';
import palette from 'lib/styles/palette';
import { useFormulaArtworkScreening } from 'service/brand/artworkScreening/artworkScreening';
import { ScreeningStatus } from 'types/brand/artworkScreening/artworkScreening';
import FullLoading from 'components/FullLoading';
import { ArtworkScreeningFormData } from 'components/brand/artworkScreening/step1/ArtworkScreeningTemplate';
import ArtworkStep5 from 'components/brand/artworkScreening/step5/ArtworkStep5';
import path from 'lib/path';
import { UserType } from 'types/auth';
import client from 'lib/api/client';

const ScreeningNavigator = ({
  steps,
  activeStep,
}: {
  steps: { id: number; title: string }[];
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <NavigatorContainer>
      {steps.map(({ id, title }) => {
        const isActive = activeStep === id;
        const isCheckedStep = activeStep > id;
        return (
          <StepDiv key={id} align="center" gap={8} active={isActive}>
            {!isActive && isCheckedStep && <Icon name="checkCircleHover" size={16} />}
            {!isCheckedStep && <NumIcon active={isActive}>{id}</NumIcon>}
            <StepTitle active={isActive}>{title}</StepTitle>
          </StepDiv>
        );
      })}
    </NavigatorContainer>
  );
};

const ArtworkScreeningPage = ({ isAdmin = false }: { isAdmin?: boolean }) => {
  const location = useLocation();
  const { search } = location;
  const queryParams = new URLSearchParams(search);
  const adminToken = queryParams.get('adminToken');

  const navigate = useNavigate();
  const { auth } = useSelector(({ auth }: any) => ({ auth }));
  const [activeStep, setActiveStep] = useState<number>(1);
  const [artworkFiile, setArtworkFiile] = useState<File | null>(null);
  const [existedArtworkFile, setExistedArtworkFile] = useState<ArtworkItem | null>(null);
  const [, setStep2DisabledMode] = useState(true);
  const [screeningForm] = useForm<ArtworkScreeningFormData>();
  const [screeningId, setScreeningId] = useState<number>();
  const [selectedPerformanceCountryCodes, setSelectedPerformanceCountryCodes] = useState<string[]>(
    [],
  );

  const {
    uploadFormulaArtworkScreeningJson,
    formulaScreeningResult,
    formulaArtworkScreeningLoading,
    formulaScreeningArtworkFile,
    formulaScreeningStatus,
    getFormulaScreeningStatusLoading,
  } = useFormulaArtworkScreening({ formulaScreeningId: screeningId });
  const isPerformanceMeasurementEnv = isAdmin;

  const params = useParams<{
    screeningId: string;
  }>();

  useEffect(() => {
    if (
      !isAdmin &&
      auth.user?.userType !== UserType.MANUFACTURE &&
      auth.user?.userType !== UserType.BRAND
    ) {
      message.error('올바르지 않은 접근입니다.');
      navigate(path.main, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (isAdmin) {
      const showError = () => {
        message.error('올바르지 않은 접근입니다.');
        navigate(path.main, { replace: true });
      };
      if (adminToken) {
        sessionStorage.setItem('auth.token', adminToken);
        client.defaults.headers.common['Authorization'] = `Bearer ${adminToken}`;
      } else {
        showError();
      }
    }
  }, [isAdmin, adminToken]);

  useEffect(() => {
    if (params.screeningId) {
      setScreeningId(Number(params.screeningId));
      formulaScreeningArtworkFile(
        { formulaScreeningId: Number(params.screeningId) },
        {
          onSuccess: (res) => {
            const artwork = res.data.result;
            setExistedArtworkFile(artwork);
          },
        },
      );
    }
  }, [params]);

  useEffect(() => {
    if (formulaScreeningResult?.formulaScreeningId) {
      setScreeningId(formulaScreeningResult.formulaScreeningId);

      screeningForm.setFieldsValue({
        ingredients: formulaScreeningResult.list.map(
          ({ ingredientName, ingredientWt, allergenRawList }) => ({
            name: ingredientName,
            wt: ingredientWt,
            allergens: allergenRawList.map(
              ({ ingredientAllergenId, ingredientInciName, allergenWt }) => ({
                ingredientAllergenId,
                allergenInciName: ingredientInciName,
                allergenWt: allergenWt,
              }),
            ),
          }),
        ),
      });
    }
  }, [formulaScreeningResult]);

  useEffect(() => {
    if (
      params.screeningId &&
      (formulaScreeningStatus?.status === ScreeningStatus.ANALYZE ||
        formulaScreeningStatus?.status === ScreeningStatus.COMPLETE)
    ) {
      setActiveStep(4);
    }
  }, [formulaScreeningStatus]);

  useEffect(() => {
    const layout = document.getElementById('app-content-block');
    if (layout) {
      layout.style.paddingBottom = '0';
    }

    return () => {
      if (layout) {
        layout.style.paddingBottom = '80px';
      }
    };
  }, []);

  const steps = [
    {
      id: 1,
      title: '전성분표 입력',
    },
    {
      id: 2,
      title: '전성분표 분석',
    },
    {
      id: 3,
      title: 'Artwork 업로드',
    },
    {
      id: 4,
      title: '스크리닝 분석 결과',
    },
    ...(isPerformanceMeasurementEnv
      ? [
          {
            id: 5,
            title: '최종 전성분 정보',
          },
        ]
      : []),
  ];

  const handleFormulaScreeningForArtwork = () => {
    const ingredients: ArtworkScreeningFormData['ingredients'] =
      screeningForm.getFieldValue('ingredients');

    const filteredIngredients = ingredients
      ?.filter(({ name }) => name && name !== null && name !== '')
      .map((item) => ({
        ...item,
        allergens: item.allergens.map(({ allergenInciName, allergenWt, ingredientAllergenId }) => ({
          allergenInciName,
          allergenWt,
          ingredientAllergenId,
        })),
      }));

    const canSubmit = !isEqual(
      filteredIngredients,
      formulaScreeningResult?.list.map(({ ingredientName, ingredientWt, allergenRawList }) => ({
        name: ingredientName,
        wt: ingredientWt,
        allergens: allergenRawList.map(
          ({ ingredientAllergenId, ingredientInciName, allergenWt }) => ({
            allergenInciName: ingredientInciName,
            allergenWt,
            ingredientAllergenId,
          }),
        ),
      })),
    );

    if (!canSubmit) return setActiveStep(2);

    uploadFormulaArtworkScreeningJson(
      {
        ingredients: filteredIngredients.map((item) => ({
          name: item.name.trimEnd(),
          ingredientWt: item.wt,
          allergenList: item.allergens.map(
            ({ ingredientAllergenId, allergenInciName, allergenWt }) => ({
              ingredientAllergenId,
              name: allergenInciName,
              allergenWt,
            }),
          ),
        })),
      },
      {
        onSuccess: () => {
          setActiveStep(2);
        },
      },
    );
  };

  if (getFormulaScreeningStatusLoading) return <FullLoading />;

  return (
    <Container>
      <ScreeningNavigator steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} />
      {activeStep === 1 ? (
        <ArtworkStep1
          form={screeningForm}
          setStep2DisabledMode={setStep2DisabledMode}
          handleFormulaScreeningForArtwork={handleFormulaScreeningForArtwork}
          formulaArtworkScreeningLoading={formulaArtworkScreeningLoading}
        />
      ) : activeStep === 2 ? (
        <ArtworkStep2
          isAdmin={isAdmin}
          setStep={setActiveStep}
          formulaScreeningResult={formulaScreeningResult}
          adminToken={adminToken}
        />
      ) : activeStep === 3 ? (
        <ArtworkStep3
          isAdmin={isAdmin}
          artworkFile={artworkFiile}
          setArtworkFile={setArtworkFiile}
          setStep={setActiveStep}
          screeningId={screeningId}
          existedArtworkFile={existedArtworkFile}
          setExistedArtworkFile={setExistedArtworkFile}
          adminToken={adminToken}
        />
      ) : activeStep === 4 ? (
        activeStep === 4 &&
        screeningId && (
          <ArtworkStep4
            isPerformanceMeasurementEnv={isPerformanceMeasurementEnv}
            formulaScreeningId={screeningId}
            setStep={setActiveStep}
            isAdmin={isAdmin}
            selectedPerformanceCountryCodes={selectedPerformanceCountryCodes}
            setSelectedPerformanceCountryCodes={setSelectedPerformanceCountryCodes}
          />
        )
      ) : (
        activeStep === 5 &&
        screeningId && (
          <>
            <ArtworkStep5
              setStep={setActiveStep}
              adminToken={adminToken}
              formulaScreeningId={screeningId}
              selectedPerformanceCountryCodes={selectedPerformanceCountryCodes}
            />
          </>
        )
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: -58px !important;
  max-width: 100vw !important;
`;

const NavigatorContainer = styled.div`
  width: 216px;
  border-right: 1px solid ${palette.GRAY30};
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  padding: 136px 16px;
`;

const StepDiv = styled(Flex)<{ active: boolean }>`
  padding: 18px 16px;
  border-radius: 4px;
  ${({ active }) => css`
    background-color: ${active ? `${palette.SLATE_GRAY10}` : `transparent`};
  `};
`;

const NumIcon = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) =>
    active ? `${palette.PRIMARY50}` : `${palette.SLATE_GRAY50}`};
  border-radius: 100px;
  font-size: 12px;
  color: white;
`;

const StepTitle = styled(Typography.Text)<{ active: boolean }>`
  font-size: 14px;
  font-weight: ${({ active }) => (active ? `500` : `400`)};
  text-align: center;
  color: ${({ active }) => (active ? `${palette.SLATE_GRAY70}` : `${palette.SLATE_GRAY50}`)};
`;

export default ArtworkScreeningPage;
