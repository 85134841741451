import { Button, Form, FormInstance } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { focusToInvalidatedField } from 'lib/form';
import LoadingModal from 'components/ui/Modal/LoadingModal';
import StickyButtonWrapper from '../StickyButtonWrapper';
import ArtworkScreeningTemplete, { ArtworkScreeningFormData } from './ArtworkScreeningTemplate';
import palette from 'lib/styles/palette';
import { apiUrls } from 'service/core';
import client from 'lib/api/client';

const ArtworkStep1 = ({
  form,
  setStep2DisabledMode,
  formulaArtworkScreeningLoading = false,
  handleFormulaScreeningForArtwork,
}: {
  form: FormInstance<ArtworkScreeningFormData>;
  setStep2DisabledMode: React.Dispatch<React.SetStateAction<boolean>>;
  formulaArtworkScreeningLoading?: boolean;
  handleFormulaScreeningForArtwork?: () => void;
}) => {
  const [excelLoading, setExcelLoading] = useState(false);

  const prevStep2 = () => {
    handleFormulaScreeningForArtwork?.();
  };

  const goNextPage = () => {
    form
      .validateFields()
      .then(() => {
        prevStep2();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownloadToExcel = async () => {
    const ingredients = form.getFieldValue('ingredients');
    if (!ingredients) return;

    const today = new Date().toISOString().split('T')[0];

    setExcelLoading(true);
    const { data: blobFile } = await client.post(
      `${apiUrls.artworkScreeningExcelDownload}`,
      ingredients,
      {
        responseType: 'blob',
      },
    );

    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blobFile);
    a.download = decodeURIComponent(`${today}_성분스크리닝.xlsx`);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setExcelLoading(false);
  };

  return (
    <Container>
      <LoadingModal
        visible={formulaArtworkScreeningLoading}
        textType="BODY_2"
        content={`업로드하신 파일의 전성분표 화면을 로딩하고 있습니다.\n잠시만 기다려 주세요 :)`}
      />
      <Form
        form={form}
        style={{ width: '100%' }}
        onFinishFailed={focusToInvalidatedField({ form, offsetY: -300 })}
        onFieldsChange={(_, allFields) => {
          setStep2DisabledMode(
            allFields
              .filter(
                ({ name }) =>
                  Array.isArray(name) &&
                  name.length === 3 &&
                  (name[2] === 'name' || name[2] === 'wt'),
              )
              .every(({ value }) => !value),
          );
        }}
      >
        <InnerContainer>
          <Flex dir="column" gap={6} gutter={{ top: 60 }}>
            <Typography.Text type="HEADLINE_1" color="GRAY90" style={{ fontWeight: 300 }}>
              아트웍 스크리닝
            </Typography.Text>
            <Typography.Text type="BODY_2" color="GRAY70">
              전성분표를 기준으로 Artwork의 전성분 내용을 국가 규제별로 스크리닝 할 수 있습니다.
            </Typography.Text>
          </Flex>
          <ArtworkScreeningTemplete form={form} />
        </InnerContainer>
        <StickyButtonWrapper>
          <Form.Item shouldUpdate={() => true} noStyle>
            {({ getFieldValue }) => {
              const disabledMode =
                getFieldValue('ingredients')?.filter(
                  ({ name, wt }: { name: string; wt: string }) => name === '' || !name || !wt,
                ).length === getFieldValue('ingredients').length;

              return (
                <Form.Item noStyle>
                  <Flex gap={16}>
                    <Button
                      style={{
                        width: 200,
                        height: 56,
                        fontSize: 18,
                        borderColor: palette.GRAY40,
                        color: palette.GRAY90,
                      }}
                      loading={excelLoading}
                      onClick={handleDownloadToExcel}
                    >
                      엑셀로 내보내기
                    </Button>
                    <Button
                      style={{
                        width: 200,
                        height: 56,
                        fontSize: 18,
                      }}
                      htmlType="submit"
                      type="primary"
                      onClick={goNextPage}
                      disabled={disabledMode}
                    >
                      분석하기
                    </Button>
                  </Flex>
                </Form.Item>
              );
            }}
          </Form.Item>
        </StickyButtonWrapper>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const InnerContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

export default ArtworkStep1;
