import { Button } from 'antd';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { useCountryCodeToNameMap } from 'service/country';
import {
  AnalysisItem,
  Coordinate,
  CountryRules,
  IngredientCoordinate,
  PostIngredient,
  PostIngredientListItem,
  RuleCode,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import InquiryButton from './InquiryButton';
import FullLoading from 'components/FullLoading';
import path from 'lib/path';
import StickyButtonWrapper from '../StickyButtonWrapper';
import CountryList from './CountryList';
import ArtworkIngredientList from './ArtworkIngredientList';
import IngredientImageModal from './IngredientImageModal';
import UpdatedIngredientList from './UpdatedIngredientList';
import ReportModalContent from './ReportModalContent';
import { scrollToText } from 'lib/artworkScreening';
import { useFormulaArtworkScreeningResult } from 'service/brand/artworkScreening/artworkScreening';

export interface AllError {
  ruleType: RuleType;
  analysisItems: AnalysisItem[];
  errors: {
    countryRules: CountryRules;
    ruleCode: RuleCode;
    postIngredient: PostIngredientListItem | PostIngredient;
  }[];
  isUpdate: boolean;
}

export interface UpdateInfo {
  [key: string]: {
    initialIngredientName: string;
    ingredientName: string;
    directName: string;
    color: keyof typeof palette;
    initialColor: keyof typeof palette;
    additionalIngredientName?: string;
    additionalIngredientNameSeparator?: 'SLASH' | 'PARENTHESIS' | null;
    formulaScreeningArtworkImageIngredientId: number | undefined;
    formulaScreeningArtworkIngredientId: number | undefined;
    allErrors: AllError[];
    order: number;
    initialOrder: number;
  };
}

export interface UpdatedIngredient extends UpdatedIngredientItem {
  relatedIngredients: UpdatedIngredientItem[];
}

export interface UpdatedIngredientItem {
  formulaScreeningAnalyzeItemId: number;
  color: keyof typeof palette;
  initialColor: keyof typeof palette;
  initialIngredientName: string;
  ingredientName: string;
  additionalIngredientName: string;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  directName: string;
  name: string;
  isNotMatch: boolean;
  isMiss: boolean;
  formulaScreeningArtworkImageIngredientId: number | undefined;
  coordinates: Coordinate[];
  allErrors: AllError[];
  order: number;
  initialOrder: number;
}

const ArtworkStep4 = ({
  setStep,
  formulaScreeningId,
  isAdmin = false,
  selectedPerformanceCountryCodes,
  setSelectedPerformanceCountryCodes,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formulaScreeningId: number;
  isPerformanceMeasurementEnv: boolean;
  isAdmin?: boolean;
  selectedPerformanceCountryCodes: string[];
  setSelectedPerformanceCountryCodes: (countryCodes: string[]) => void;
}) => {
  /**
   * ingredientCoordinate: 이미지 모달의 성분 좌표
   * targetRect: overflow로 인한 툴팁 잘림을 막기 위해 툴팁을 fixed로 표시. 마우스 올렸을 때의 툴팁위치를 저장하기 위한 상태
   * isMinimize: 이미지 모달 최소화 여부
   * clickedIngredientArea: 성분 클릭시 스크롤하기 위한 아이디 저장용 상태
   */
  const [ingredientCoordinate, setIngredientCoordinate] = useState<IngredientCoordinate[]>([]);
  const [targetRect, setTargetRect] = useState<DOMRect>();
  const [isMinimize, setIsMinimize] = useState(false);
  const [clickedIngredientArea, setClickedIngredientArea] = useState<{
    type?: 'formulaScreeningAnalyzeItemId' | 'formulaScreeningArtworkImageIngredientId';
    id?: number;
  }>({});

  const itemsRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const navigate = useNavigate();
  const { openAlertModal, openConfirmModal } = useModal();
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const {
    availableCountryCodes,
    selectedCountryCodes,
    setSelectedCountryCodes,
    artworkScreeningResult,
    artworkIngredients,
    setArtworkIngredients,
    updatedArtworkIngredients,
    handleReport,
  } = useFormulaArtworkScreeningResult({
    formulaScreeningId,
  });

  /**
   * (성분 클릭시)
   * 1. 해당 성분으로 스크롤
   * 2. 해당 성분 이미지 모달에 표시
   */
  useEffect(() => {
    if (!clickedIngredientArea.type || !clickedIngredientArea.id) {
      return;
    }

    if (clickedIngredientArea.type === 'formulaScreeningAnalyzeItemId') {
      const targetIngredient = artworkIngredients.find(
        (item) => item.id === clickedIngredientArea.id,
      );
      const target = itemsRef.current.get(clickedIngredientArea.id);
      scrollToText({ target, targetIngredient });
    } else {
      const targetIngredient = artworkIngredients.find(
        (item) =>
          item.imageIngredient?.formulaScreeningArtworkImageIngredientId ===
          clickedIngredientArea.id,
      );
      const formulaScreeningAnalyzeItemId = targetIngredient?.id;
      if (formulaScreeningAnalyzeItemId) {
        const target = itemsRef.current.get(formulaScreeningAnalyzeItemId);
        scrollToText({ target, targetIngredient });
        setIngredientCoordinate(targetIngredient.coordinates);
        setIsMinimize(false);
      }
    }
  }, [clickedIngredientArea]);

  if (!artworkScreeningResult) {
    return <FullLoading />;
  }

  const errorCount = artworkIngredients.filter((item) => item.errors.length > 0).length;

  return (
    <Container>
      <InnerContainer>
        <Title justify="space-between">
          <Flex gap={8}>
            <Icon name="robot" size={24} />
            <Typography.Text type="TITLE_1">스크리닝 분석 결과</Typography.Text>
          </Flex>
          {/* 문의 보내기 */}
          {!isAdmin && <InquiryButton formulaScreeningId={formulaScreeningId} />}
        </Title>
        {/* 국가 목록 */}
        <CountryList
          availableCountryCodes={availableCountryCodes}
          countryCodeToNameMap={countryCodeToNameMap}
          artworkIngredients={artworkIngredients}
          selectedCountryCodes={selectedCountryCodes}
          selectedPerformanceCountryCodes={selectedPerformanceCountryCodes}
          setSelectedCountryCodes={setSelectedCountryCodes}
          setSelectedPerformanceCountryCodes={setSelectedPerformanceCountryCodes}
          isAdmin={isAdmin}
        />
        <ArtworkScreeningResult gap={8} gutter={{ top: 32 }}>
          {/* Artwork 전성분 리스트 */}
          <ArtworkIngredientList
            formulaScreeningId={formulaScreeningId}
            targetRect={targetRect}
            setTargetRect={setTargetRect}
            artworkIngredients={artworkIngredients}
            setClickedIngredientArea={setClickedIngredientArea}
            errorCount={errorCount}
            setArtworkIngredients={setArtworkIngredients}
            selectedCountryCodes={selectedCountryCodes}
            handleReport={handleReport}
          />
          {/* 구분선 */}
          <Divider dir="column" gap={24}>
            <VerticalLine />
            <FrontIconWrapper>
              <Icon name="front" size={18} color="GRAY40" />
            </FrontIconWrapper>
            <VerticalLine />
          </Divider>
          {/* 적용 리스트 */}
          <UpdatedIngredientList
            artworkIngredients={artworkIngredients}
            updatedArtworkIngredients={updatedArtworkIngredients}
            setArtworkIngredients={setArtworkIngredients}
            targetRect={targetRect}
            setTargetRect={setTargetRect}
            errorCount={errorCount}
            clickedIngredientArea={clickedIngredientArea}
            setIngredientCoordinate={setIngredientCoordinate}
            setIsMinimize={setIsMinimize}
            itemsRef={itemsRef}
          />
        </ArtworkScreeningResult>
        {/* 검토 결과 확인하기 */}
        {!isAdmin && (
          <ReportModalDescription justify="center" gap={16}>
            <Typography.Text color="SLATE_GRAY70">
              검토 내용을 비교하고 언어별로 확인해 보세요.
            </Typography.Text>
            <Typography.Text
              color="PRIMARY50"
              semiBold
              underline
              onClick={() => {
                openConfirmModal({
                  title: '',
                  width: 832,
                  content: (
                    <ReportModalContent
                      artworkIngredients={artworkIngredients}
                      updatedArtworkIngredients={updatedArtworkIngredients}
                    />
                  ),
                  footer: null,
                });
              }}
              style={{ padding: '2px 4px', textUnderlineOffset: 2 }}
            >
              확인하기
            </Typography.Text>
          </ReportModalDescription>
        )}
        {/* 전성분 이미지 모달 */}
        <IngredientImageModal
          ingredientCoordinate={ingredientCoordinate}
          url={artworkScreeningResult.artworkImage.url}
          isMinimize={isMinimize}
          setIsMinimize={setIsMinimize}
        />
      </InnerContainer>
      <StickyButtonWrapper>
        <Flex align="center" justify={isAdmin ? 'space-between' : 'end'} style={{ width: '100%' }}>
          {isAdmin && (
            <RestartButton
              justify="center"
              onClick={() => {
                openAlertModal({
                  content: '처음부터 다시 하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                  onOk: () => {
                    navigate(path.artworkScreening, { replace: true });
                    window.location.reload();
                  },
                });
              }}
            >
              처음부터 다시 하기
            </RestartButton>
          )}
          <Flex align="center" gap={16}>
            {!isAdmin && (
              <Button style={{ width: 200, height: 56, fontSize: 18 }} onClick={() => setStep(3)}>
                이전
              </Button>
            )}
            {isAdmin && (
              <StyledButton
                htmlType="button"
                type="primary"
                onClick={() => {
                  navigator.clipboard
                    .writeText(
                      updatedArtworkIngredients
                        .map(({ name, newDirectName, updatedName }) => {
                          const matchDirectName = Object.values(newDirectName).find(
                            (name) => name && name !== '',
                          );
                          return matchDirectName
                            ? matchDirectName
                            : updatedName
                            ? updatedName
                            : name;
                        })
                        .join(', '),
                    )
                    .then(() => {
                      openAlertModal({
                        content: `'적용 리스트' 내용이 복사되었습니다.`,
                        onOk: () => {
                          setStep(5);
                        },
                      });
                    });
                }}
              >
                다음
              </StyledButton>
            )}
          </Flex>
        </Flex>
      </StickyButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 58px;

  ${Flex} {
    align-items: center;
  }
`;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

const ArtworkScreeningResult = styled(Flex)`
  .ant-empty-normal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 736px;
    margin: 0;
  }
`;

const Title = styled(Flex)`
  padding-bottom: 8px;
  border-bottom: 2px solid ${palette.PRIMARY50};
  margin-bottom: 16px;
`;

const Divider = styled(Flex)`
  flex: 0 0 40px;
  align-self: stretch;
  margin-top: 28px;
`;

const VerticalLine = styled.div`
  flex: 1;
  width: 1px;
  background-color: ${palette.GRAY30};
`;

const FrontIconWrapper = styled(Flex)`
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${palette.GRAY40};
`;

const ReportModalDescription = styled(Flex)`
  margin-top: 16px;
  height: 80px;
  background-color: ${palette.PRIMARY10};
  border-radius: 8px;
`;

const RestartButton = styled(Flex)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  color: ${palette.GRAY80};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;
const StyledButton = styled(Button)`
  width: 200px;
  height: 56px;
  font-size: 18px;
`;

export default ArtworkStep4;
