import React from 'react';
import { Modal } from 'antd';
import { useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import { DistributorLawRegulation } from 'service/brand/formulaScreening/formulaScreening';
import Tags from 'components/ui/Tags';
import path from 'lib/path';

const DistributorProhibitInfosModal = ({
  defaultDistributorId,
  distributorRegulations,
  onClose,
  ingredientInfo,
}: {
  defaultDistributorId?: number;
  distributorRegulations: DistributorLawRegulation[];
  onClose: () => void;
  ingredientInfo?: {
    ingredientName: string;
    ingredientId: number;
  };
}) => {
  const [selectedDistributorId, setSelectedDistributorId] = useState(
    defaultDistributorId ||
      Number(
        distributorRegulations.map(
          (item) => item.distributorDto && item.distributorDto.distributorId,
        )[0],
      ),
  );
  const selectedDistributorInfo = distributorRegulations.find(
    (item) => item.distributorDto && item.distributorDto.distributorId === selectedDistributorId,
  )!;

  const { ingredientLawRegulations, groupingLawRegulations } = {
    ...selectedDistributorInfo,
  };

  const combinedRegulations = [
    ...ingredientLawRegulations.map((regulation) => ({
      type: 'ingredient',
      law: regulation.law,
      regulations: regulation.regulations || [],
    })),
    ...groupingLawRegulations.map((groupingRegulation) => ({
      type: 'grouping',
      law: groupingRegulation.law,
      regulations: groupingRegulation.groupingLawRegulations.map((reg) => reg.regulation) || [],
    })),
  ];

  const groupedByLaw = combinedRegulations.reduce((acc, cur, idx) => {
    const existingGroup = acc.find((group) => group.law.lawId === cur.law.lawId);
    if (existingGroup) {
      existingGroup.regulations.push(...cur.regulations);
    } else {
      acc.push({ law: cur.law, regulations: [...cur.regulations] });
    }
    return acc;
  }, [] as { law: { lawId: number; name: string }; regulations: any[] }[]);

  return (
    <Modal
      open={true}
      width={896}
      onCancel={onClose}
      footer={null}
      centered
      styles={{ body: { padding: 0, height: 560 } }}
      closable={false}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          성분 규제사항
          {ingredientInfo?.ingredientName && (
            <>
              {' '}
              -
              <Typography.Text
                type="TITLE_2"
                color="GRAY90"
                inline
                underline
                style={{ fontWeight: 600 }}
                onClick={() => {
                  window.open(`${path.material.ingredient.detail}/${ingredientInfo?.ingredientId}`);
                }}
              >
                {ingredientInfo?.ingredientName}
              </Typography.Text>
            </>
          )}
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true">
          {distributorRegulations
            .filter(({ distributorDto }) => distributorDto)
            ?.reduce((acc, cur) => {
              if (
                !acc.find(
                  (item) => item.distributorDto.distributorId === cur.distributorDto.distributorId,
                )
              ) {
                acc.push(cur);
              }
              return acc;
            }, [] as DistributorLawRegulation[])
            .map(({ distributorDto }) => (
              <Tab
                label={distributorDto.distributorName}
                key={distributorDto?.distributorId}
                isActive={Number(distributorDto?.distributorId) === selectedDistributorId}
                onClick={() =>
                  Number(distributorDto?.distributorId) !== selectedDistributorId &&
                  setSelectedDistributorId(Number(distributorDto?.distributorId))
                }
              >
                {distributorDto.distributorName}
              </Tab>
            ))}
        </Flex>
        {selectedDistributorInfo && (
          <StyledTable>
            <thead>
              <HeaderItem style={{ width: '160px' }}>규제명</HeaderItem>
              <HeaderItem style={{ width: '280px' }}>고시원료명</HeaderItem>
              <HeaderItem style={{ width: '376px' }}>제한사항</HeaderItem>
            </thead>
            <TableBody>
              {groupedByLaw.map(({ law, regulations }) => (
                <React.Fragment key={law.lawId}>
                  <tr>
                    <BodyItem rowSpan={regulations.length + 1 || 1} style={{ width: '160px' }}>
                      {law.name}
                    </BodyItem>
                  </tr>
                  {regulations.map((regulation, idx) => (
                    <tr key={`${law.lawId}-${regulation.regulationId || idx}`}>
                      <BodyItem width={280}>{regulation?.notifiedIngredientName || '-'}</BodyItem>
                      <BodyItem width={280} style={{ borderRight: 'none' }}>
                        <div>
                          <Tags.Mark
                            color={regulation?.type === 'LIMIT' ? 'green' : 'red'}
                            style={{ margin: '0 0 8px 0' }}
                          >
                            {regulation?.type === 'LIMIT' ? '제한' : '금지'}
                          </Tags.Mark>
                          {regulation?.regulationsOfLanguage.find(
                            (item: any) => item.languageCode === 'KO',
                          )?.limitedMatter || ''}
                        </div>
                      </BodyItem>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </StyledTable>
        )}
      </ModalBody>
    </Modal>
  );
};

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
  overflow-y: scroll;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const StyledTable = styled.div`
  margin-top: 24px;
  border-top: 2px solid ${palette.PRIMARY50};
`;

const HeaderItem = styled.th`
  padding: 12px 16px;
  background-color: ${palette.GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }
`;

const TableBody = styled.tbody``;

const BodyItem = styled.td`
  padding: 12px 16px;
  background-color: ${palette.ETC_WHITE};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  white-space: pre-wrap;
`;

export default DistributorProhibitInfosModal;
