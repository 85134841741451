import {
  ArtworkIngredient,
  ArtworkIngredientError,
  PostIngredient,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import palette from './styles/palette';

export const ruleCategories: {
  type: RuleType;
  text: string;
  color: keyof typeof palette;
}[] = [
  {
    type: RuleType.TYPO,
    text: '표기 오류',
    color: 'MESSAGE_ERROR',
  },
  {
    type: RuleType.ORDER,
    text: '함량 순서',
    color: 'LIGHT_BLUE40',
  },
  {
    type: RuleType.MISS,
    text: '미기입',
    color: 'PURPLE_50',
  },
  {
    type: RuleType.NOT_MATCH,
    text: '매칭 결과 없음',
    color: 'GREEN_50',
  },
  {
    type: RuleType.CHECK,
    text: '확인 필요',
    color: 'YELLOW_70',
  },
];

// 국가 우선순위
export const countryOrder: {
  [key: string]: number;
} = {
  EU: 1,
  GB: 2,
  KR: 3,
  CN: 4,
  JP: 5,
  US: 6,
  ETC: 7,
};

export const initialInquiry = {
  [RuleType.TYPO]: {
    isOpen: false,
    content: '',
  },
  [RuleType.ORDER]: {
    isOpen: false,
    content: '',
  },
  [RuleType.MISS]: {
    isOpen: false,
    content: '',
  },
  [RuleType.NOT_MATCH]: {
    isOpen: false,
    content: '',
  },
  [RuleType.CHECK]: {
    isOpen: false,
    content: '',
  },
};

// 오류 우선순위가 높은 순서대로 색상 반환
export const getTextColorByRule = (rule?: RuleType) => {
  return ruleCategories.find((item) => item.type === rule)?.color || 'SLATE_GRAY70';
};

// 모든 오류가 수정된 경우 파란색 반환. 그렇지 않은 경우 수정되지 않은 오류의 rule에 따라 색상 반환
export const getTextColorByErrors = (errors: ArtworkIngredientError[]) => {
  if (errors.length > 0 && errors.every((error) => error.isUpdate)) {
    return 'PRIMARY50';
  }

  return getTextColorByRule(errors.find((error) => !error.isUpdate)?.ruleType);
};

// 추가 정보를 포함한 성분 이름 반환
export const getIngerdientNameWithAdditional = ({
  ingredientName,
  additionalIngredientName,
  additionalIngredientNameSeparator,
}: {
  ingredientName?: string;
  additionalIngredientName?: string | null;
  additionalIngredientNameSeparator?: 'SLASH' | 'PARENTHESIS' | null;
}) => {
  if (ingredientName && additionalIngredientName && additionalIngredientNameSeparator) {
    return `${ingredientName}${
      additionalIngredientNameSeparator === 'SLASH'
        ? `/${additionalIngredientName}`
        : `(${additionalIngredientName})`
    }`;
  }

  return ingredientName || '';
};

// 적용 리스트에 있는 특정 성분으로 스크롤
export const scrollToText = ({
  target,
  targetIngredient,
}: {
  target?: HTMLDivElement;
  targetIngredient?: ArtworkIngredient;
}) => {
  if (target && targetIngredient) {
    const scrollContainer = target.parentElement;
    const firstElement = scrollContainer?.firstElementChild as HTMLElement | undefined;
    const targetPosition =
      (target?.offsetTop || 0) -
      (firstElement?.offsetTop || 0) -
      ((scrollContainer?.clientHeight || 0) - 72) / 2;

    scrollContainer?.scrollTo({
      top: targetPosition,
      behavior: 'smooth',
    });
  }
};

/**
 * 배열의 아이템 중 ruleSubCodes가 모두 매칭되는 마지막 아이템 찾기
 * @param items - postIngredient 목록
 * @param subCodes - 선택된 국가의 ruleSubCode 목록
 */
export const findLastMatchingItem = (
  items: PostIngredient[],
  subCodes: string[],
  ruleType: RuleType,
) => {
  if (ruleType === RuleType.CHECK && items.length > 1) {
    const filteredItems = items.filter((item) =>
      item.updateIngredient?.every(
        ({ ingredientName }) => ingredientName !== null && ingredientName !== '',
      ),
    );
    for (let i = filteredItems.length - 1; i >= 0; i--) {
      const ruleSubCodes = items[i].ruleSubCode;

      if (ruleSubCodes.some((subCode) => subCodes.includes(subCode))) {
        return items[i];
      }
    }
    return null;
  }

  for (let i = items.length - 1; i >= 0; i--) {
    const ruleSubCodes = items[i].ruleSubCode;

    if (ruleSubCodes.every((subCode) => subCodes.includes(subCode))) {
      return items[i];
    }
  }
  return null;
};
