import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import styled from 'styled-components';

const StatusIconBlock = styled.span<{
  status: 'success' | 'error' | 'warning' | 'info';
}>`
  display: inline-flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  border-radius: 50%;
  background-color: ${({ status }) =>
    status === 'success'
      ? palette.MESSAGE_SUCCESS
      : status === 'warning'
      ? palette.YELLOW_60
      : status === 'info'
      ? palette.PRIMARY50
      : palette.MESSAGE_ERROR};

  i {
    font-size: 8px;
    color: #fff;
  }
`;

const StatusIcon = ({
  status,
  style,
}: {
  status: 'success' | 'error' | 'warning' | 'info';
  style?: React.CSSProperties;
}) => {
  const renderIcon = () => {
    switch (status) {
      case 'success':
        return <Icon name="success" size={16} />;
      case 'error':
        return <Icon name="error" size={16} />;
      case 'warning':
        return <Icon name="warning" size={16} />;
      case 'info':
        return <Icon name="info" size={16} />;
      default:
        return null;
    }
  };
  return (
    <StatusIconBlock status={status} style={style}>
      {renderIcon()}
    </StatusIconBlock>
  );
};

export default StatusIcon;
