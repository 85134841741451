import styled from 'styled-components';

import { usePubCountries } from 'service/country';
import { useCountryCodeToNameMap } from 'service/country';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { countryImages } from 'lib/consts';
import { Typography } from 'components/system';

const CountryModalContent = ({
  countryRules,
}: {
  countryRules: {
    countryCodes: string[];
    descriptions: string[];
  }[];
}) => {
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const countries = usePubCountries();

  return (
    <Flex gap={8} dir="column" align="stretch">
      {countryRules.map(({ countryCodes, descriptions }, idx) => {
        const filteredDescriptions = descriptions.flatMap((item) =>
          item.split('\n').filter((item) => item !== ''),
        );

        return (
          <div
            key={idx}
            style={{
              border: `1px solid ${palette.GRAY30}`,
              borderRadius: 8,
              padding: 24,
            }}
          >
            <Flex gap={8}>
              {countryCodes.map((countryCode) => (
                <CountryImgWrapper key={countryCode}>
                  <img
                    key={countryCode}
                    src={countryImages[countryCodeToNameMap[countryCode]]}
                    alt={countryCode}
                    style={{
                      width: 20,
                    }}
                  />
                  <Tooltip>
                    {countryCode === 'ETC'
                      ? '기타'
                      : countries?.find((item) => item.countryCode === countryCode)?.countryNameKo}
                  </Tooltip>
                </CountryImgWrapper>
              ))}
            </Flex>
            <div style={{ marginTop: 8 }}>
              {filteredDescriptions.map((description, idx) => {
                return (
                  <Flex gap={4} align="center" gutter={{ top: 4 }} key={idx}>
                    <Bullet />
                    <Typography.Text
                      color="SLATE_GRAY70"
                      type="BODY_2"
                      style={{ whiteSpace: 'pre-wrap', width: '100%', textAlign: 'start' }}
                    >
                      {description}
                    </Typography.Text>
                  </Flex>
                );
              })}
            </div>
          </div>
        );
      })}
    </Flex>
  );
};

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, -100%);
  padding: 4px 10px;
  border-radius: 4px;
  background: ${palette.SLATE_GRAY70};
  color: #fff;
  font-size: 12px;
  width: max-content;
  z-index: 2;
`;

const CountryImgWrapper = styled.div`
  position: relative;
  height: 20px;

  img {
    vertical-align: initial;
  }

  &:hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

const Bullet = styled.div`
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
  background-color: ${palette.SLATE_GRAY50};
  align-self: flex-start;
  margin-top: 9px;
`;

export default CountryModalContent;
