import { RegulationType } from 'service/brand/formulaScreening/formulaScreening';

export enum IngredientSearchType {
  NAME = 'NAME', // 성분명
  INCI_NAME = 'INCI_NAME', // INCI NAME
  CAS_NO = 'CAS_NO', // CAS 번호
  EC_NO = 'EC_NO', // ECNO 번호
}

export interface IngredientListItem {
  ingredientId: number;
  name: {
    ingredientNameId: number;
    languageCode: string;
    name: string;
  };
  inciNames:
    | {
        ingredientId: number;
        ingredientInciNameId: number;
        name: string;
      }[]
    | null;
  casNos:
    | {
        ingredientCasNoId: number;
        casNo: string;
      }[]
    | null;
  ecNos:
    | {
        ingredientEcNoId: number;
        ecNo: string;
      }[]
    | null;
}

export interface NameItem {
  ingredientNameId: number;
  languageCode: string;
  name: string;
}

export interface LanguageNameItem {
  standardName: NameItem;
  oldNames: NameItem[];
  aliasNames: NameItem[];
  ingredientNameId: number;
  languageCode: string;
  name: string;
}

export interface IngredientDetail {
  nameKo: LanguageNameItem;
  nameEn: LanguageNameItem;
  nameCn: LanguageNameItem;
  nameJp: LanguageNameItem;
  inciName: NameItem[];
  inciOldName: NameItem[];
  oldName: {
    ingredientNameId: number;
    languageCode: string;
    name: string;
  }[];
  aliasNames: {
    ingredientNameId: number;
    languageCode: string;
    name: string;
  }[];
  casNos: {
    ingredientCasNoId: number;
    casNo: string;
  }[];
  ecNos: {
    ingredientEcNoId: number;
    ecNo: string;
  }[];
  functions: {
    ingredientId: number;
    ingredientFunctionId: number;
    function: string;
  }[];
  ewg: {
    ingredientId: number;
    ingredientEwgId: number;
    scoreMin: number;
    scoreMax: number;
  };
  origins: {
    ingredientOriginId: number;
    originId: number;
    name: string;
  }[];
  definition: {
    ingredientInfoLanguageId: number;
    languageCode: string;
    definition: string;
  }[];
  countryLawRegulations: CountryLawRegulation[];
}

export interface CountryLawRegulation {
  ingredientId: number;
  country: Country;
  ingredientLawRegulations: IngredientLawRegulation[];
  groupingLawRegulations: GroupingLawRegulation[];
}

export interface Country {
  countryId: number;
  code: string;
  nameKo: string;
  nameEn: string;
  logoUrl: string;
}

export interface GroupingLawRegulation {
  groupingId: number;
  groupingLawId: number;
  law: Law;
  groupingLawRegulations: {
    deleteReason: null;
    groupingLawId: number;
    groupingLawRegulationId: number;
    regulation: Regulation | null;
    status: string;
  }[];
  dataOrigins: DataOrigin[];
  updateDt: string;
  fieldStatus: string;
}

export interface DataOrigin {
  referenceInfoId: number;
  name: string;
  url: string;
}

export interface Law {
  lawId: number;
  name: string;
  version: string;
  establishmentDate: string;
  announcementDate: string;
  implementationDate: string;
  latestRevisionDate: string;
  latestRevisionImplementationDate: string;
  repealDate: string;
  referenceInfos: DataOrigin[];
}

export enum RegulationCategoryType {
  REGULATION = 'REGULATION',
  C_LABEL = 'C_LABEL',
  C_REGULATION = 'C_REGULATION',
}

export enum ColorantType {
  COLORANT_POSSIBLE = 'COLORANT_POSSIBLE',
  AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED',
  COLORANT_NOT_POSSIBLE = 'COLORANT_NOT_POSSIBLE',
}

export interface RegulationLabelingItem {
  regulationLabelingId: number;
  regulationId: number;
  ingredientName: {
    ingredientId: number;
    ingredientNameId: number;
    languageCode: string;
    name: string;
    nameType: string;
  };
}

export interface Regulation {
  category: RegulationCategoryType;
  categoryOfMaxContent: string;
  colorantType: ColorantType;
  maxContent: number;
  notifiedIngredientName: string;
  provisoryClause: string;
  regulationLabeling: RegulationLabelingItem;
  regulationsOfLanguage: RegulationsOfLanguage[];
  regulationId: number;
  type: RegulationType;
}

export interface RegulationsOfLanguage {
  languageCode: string;
  limitedMatter: string;
}

export interface IngredientLawRegulation {
  ingredientLawId: number;
  lawIngredientWhitelistId: number;
  status: string;
  deleteReason: null;
  law: Law;
  regulations: Regulation[] | null;
  dataOrigins: DataOrigin[];
}

export interface CompareIngredientItem {
  ingredientId: number;
  name: {
    ingredientNameId: number;
    languageCode: string;
    name: string;
  };
  inciName: {
    ingredientId: number;
    ingredientInciNameId: number;
    name: string;
  }[];
  functions: {
    ingredientId: number;
    ingredientFunctionId: number;
    function: string;
  }[];
  ewg: {
    ingredientId: number;
    ingredientEwgId: number;
    scoreMin: number;
    scoreMax: number;
  };
  countryLawRegulations: CountryLawRegulation[];
}
export interface IngredientSearchResult {
  kciaIngredientId: number;
  nameKo: string;
  nameEn: string;
  oldNameKo?: string;
  oldNameEn?: string;
  registerDt?: string;
  updateDt?: string;
  nickname?: string;
  adminId: number;
  casNo?: string;
  ecNo?: string;
  functionNames?: string[];
}

export interface ScreeningIngredientResult {
  kciaIngredientId: number;
  nameEn: string;
  casNo?: string;
  ecNo?: string;
}
export interface CompositionInformation {
  inciNameKo: string;
  inciNameEn: string;
  casNo: string;
  ECNo: string;
  EWG: string;
  regulationType?: string;
  countryNameKo: string;
}
