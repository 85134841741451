import { useState, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { pick, omit } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { manuRegisterCompany, manuUpdateCompany } from 'modules/auth';
import ManuRegisterForm from 'components/auth/manu/ManuRegisterForm';
import CGMPModal from 'components/modal/product/CGMPModal';
import useUpdateEffect from 'hook/useUpdateEffect';
import { getCompany } from 'modules/company';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { useModal } from 'hook/useModal';
import { Typography } from 'components/system';
import { isObjectChanged } from 'lib/form';
import { messages } from 'lib/consts';
import path from 'lib/path';

const ManuRegisterFormContainer = ({ tab, onChangeTab }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openAlertModal, openChangePasswordModal } = useModal();
  const [form] = Form.useForm();
  const { user, company, factories, submitLoading, registerManuCompanySuccess } = useSelector(
    ({ auth, company, loading }) => ({
      user: auth.user,
      company: company.company,
      factories: company.factories,
      submitLoading: loading['auth/MANU_REGISTER_COMPANY'] || loading['auth/MANU_UPDATE_COMPANY'],
      registerManuCompanySuccess: auth.registerManuCompanySuccess,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (registerManuCompanySuccess) {
      navigate(path.register.success);
    }
  }, [registerManuCompanySuccess]);
  const updateMode = useMemo(() => user !== null && company !== null, [user, company]);
  const { data: manufacturers = [] } = useManufacturerCompanies({
    isExcludeUserInserted: true,
    enabled: !updateMode,
  });
  const originalFirstFactory = useMemo(() => updateMode && factories.find(({ seq }) => seq === 1));
  const originalSecondFactory = useMemo(() => updateMode && factories.find(({ seq }) => seq === 2));
  const originalThirdFactory = useMemo(() => updateMode && factories.find(({ seq }) => seq === 3));
  const [firstCGMP, setFirstCGMP] = useState({
    isCGMP: true,
    ISOExpiredDate: '',
    file: null,
  });
  const [secondCGMP, setSecondCGMP] = useState({
    isCGMP: true,
    ISOExpiredDate: '',
    file: null,
  });
  const [thirdCGMP, setThirdCGMP] = useState({
    isCGMP: true,
    ISOExpiredDate: '',
    file: null,
  });
  const [previewedCGMP, setPreviewedCGMP] = useState(null);

  const onChangeCGMP = (cGMP) => {
    switch (previewedCGMP) {
      case firstCGMP:
        form.validateFields(['firstCGMP']);
        setFirstCGMP(cGMP);
        break;
      case secondCGMP:
        form.validateFields(['secondCGMP', 'secondAttachButton']);
        setSecondCGMP(cGMP);
        break;
      case thirdCGMP:
        form.validateFields(['thirdCGMP', 'thirdAttachButton']);
        setThirdCGMP(cGMP);
        break;
      default:
    }
    setPreviewedCGMP(null);
  };
  const handleCloseCGMPModal = () => {
    setPreviewedCGMP(null);
  };
  const onSubmit = (formData) => {
    // 회사 정보
    const manufacturer = manufacturers.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );
    const companyForm = {
      ...(!updateMode && {
        companyNameKo:
          formData.companyNameKo === 'companyNameKoDirect'
            ? formData.companyNameKoDirect
            : formData.companyNameKo,
        companyNameEn: formData.companyNameEn,
        ...(manufacturer?.manufacturerId && {
          manufacturerId: manufacturer.manufacturerId,
        }),
        bizNumber: formData.bizNumber,
      }),
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      addressKo: formData.firstAddressKo,
      addressEn: formData.firstAddressEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }),
      ...(!updateMode && {
        bizLicenseFile: formData.bizLicenseFile,
        bizLicenseEnFile: formData.bizLicenseEnFile,
      }),
    };

    //유저 정보
    const userForm = {
      ...(!updateMode && { isSuperUser: true }),
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
    };

    // 공장 정보
    if (!updateMode) {
      // 등록
      const factoriesForm = [
        // first
        {
          seq: 1,
          addressKo: formData.firstAddressKo,
          addressEn: formData.firstAddressEn,
          ...(firstCGMP.isCGMP
            ? { cgmpUploadFile: firstCGMP.file }
            : {
                iso22716UploadFile: firstCGMP.file,
                iso22716ExpireDate: firstCGMP.ISOExpiredDate.replace(/-/g, ''),
              }),
        },

        // second
        ...(formData.secondAddressKo
          ? [
              {
                seq: 2,
                addressKo: formData.secondAddressKo,
                addressEn: formData.secondAddressEn,
                ...(secondCGMP.isCGMP
                  ? { cgmpUploadFile: secondCGMP.file }
                  : {
                      iso22716UploadFile: secondCGMP.file,
                      iso22716ExpireDate: secondCGMP.ISOExpiredDate.replace(/-/g, ''),
                    }),
              },
            ]
          : []),

        // third
        ...(formData.thirdAddressKo
          ? [
              {
                seq: 3,
                addressKo: formData.thirdAddressKo,
                addressEn: formData.thirdAddressEn,
                ...(thirdCGMP.isCGMP
                  ? { cgmpUploadFile: thirdCGMP.file }
                  : {
                      iso22716UploadFile: thirdCGMP.file,
                      iso22716ExpireDate: thirdCGMP.ISOExpiredDate.replace(/-/g, ''),
                    }),
              },
            ]
          : []),
      ];

      dispatch(
        manuRegisterCompany({
          company: {
            ...companyForm,
            companyType: 'MANUFACTURE',
            ...userForm,
            userType: 'MANUFACTURE',
          },
          factories: factoriesForm,
        }),
      );
    } else {
      // 수정

      //first
      const firstFactoryForm = {
        companyId: originalFirstFactory.companyId,
        companyFactoryId: originalFirstFactory.companyFactoryId,
        type: 'MODIFY',
        addressKo: formData.firstAddressKo,
        addressEn: formData.firstAddressEn,
        ...(firstCGMP.isCGMP &&
          !firstCGMP.file.url && {
            cgmpUploadFile: firstCGMP.file,
            iso22716UploadFileUrl: '',
          }),
        ...(firstCGMP.isCGMP &&
          firstCGMP.file.url && {
            cgmpUploadFileUrl: firstCGMP.file.url,
            iso22716UploadFileUrl: '',
          }),
        ...(!firstCGMP.isCGMP &&
          !firstCGMP.file.url && {
            iso22716UploadFile: firstCGMP.file,
            cgmpUploadFileUrl: '',
          }),
        ...(!firstCGMP.isCGMP &&
          firstCGMP.file.url && {
            iso22716UploadFileUrl: firstCGMP.file.url,
            cgmpUploadFileUrl: '',
          }),
        iso22716ExpireDate: !firstCGMP.isCGMP ? firstCGMP.ISOExpiredDate.replace(/-/g, '') : '',
      };

      //second
      const secondFactoryForm = {};
      if (originalSecondFactory) {
        secondFactoryForm.type = 'MODIFY';
        secondFactoryForm.companyId = originalSecondFactory.companyId;
        secondFactoryForm.companyFactoryId = originalSecondFactory.companyFactoryId;
        secondFactoryForm.addressKo = formData.secondAddressKo;
        secondFactoryForm.addressEn = formData.secondAddressEn;
        if (!secondCGMP.file.url) {
          if (secondCGMP.isCGMP) {
            secondFactoryForm.cgmpUploadFile = secondCGMP.file;
            secondFactoryForm.iso22716UploadFileUrl = '';
          } else {
            secondFactoryForm.iso22716UploadFile = secondCGMP.file;
            secondFactoryForm.cgmpUploadFileUrl = '';
          }
        } else {
          if (secondCGMP.isCGMP) {
            secondFactoryForm.cgmpUploadFileUrl = secondCGMP.file.url;
            secondFactoryForm.iso22716UploadFileUrl = '';
          } else {
            secondFactoryForm.iso22716UploadFileUrl = secondCGMP.file.url;
            secondFactoryForm.cgmpUploadFileUrl = '';
          }
        }
        secondFactoryForm.iso22716ExpireDate = !secondCGMP.isCGMP
          ? secondCGMP.ISOExpiredDate.replace(/-/g, '')
          : '';
      } else {
        if (formData.secondAddressKo) {
          secondFactoryForm.type = 'ADD';
          secondFactoryForm.companyId = user.companyId;
          secondFactoryForm.seq = 2;
          secondFactoryForm.manufacturerId = manufacturer.manufacturerId;
          secondFactoryForm.addressKo = formData.secondAddressKo;
          secondFactoryForm.addressEn = formData.secondAddressEn;

          if (secondCGMP.isCGMP) {
            secondFactoryForm.cgmpUploadFile = secondCGMP.file;
          } else {
            secondFactoryForm.iso22716UploadFile = secondCGMP.file;
            secondFactoryForm.iso22716ExpireDate = secondCGMP.ISOExpiredDate.replace(/-/g, '');
          }
        }
      }

      //third
      const thirdFactoryForm = {};
      if (originalThirdFactory) {
        thirdFactoryForm.type = 'MODIFY';
        thirdFactoryForm.companyId = originalThirdFactory.companyId;
        thirdFactoryForm.companyFactoryId = originalThirdFactory.companyFactoryId;
        thirdFactoryForm.addressKo = formData.thirdAddressKo;
        thirdFactoryForm.addressEn = formData.thirdAddressEn;
        if (!thirdCGMP.file.url) {
          if (thirdCGMP.isCGMP) {
            thirdFactoryForm.cgmpUploadFile = thirdCGMP.file;
            thirdFactoryForm.iso22716UploadFileUrl = '';
          } else {
            thirdFactoryForm.iso22716UploadFile = thirdCGMP.file;
            thirdFactoryForm.cgmpUploadFileUrl = '';
          }
        } else {
          if (thirdCGMP.isCGMP) {
            thirdFactoryForm.cgmpUploadFileUrl = thirdCGMP.file.url;
            thirdFactoryForm.iso22716UploadFileUrl = '';
          } else {
            thirdFactoryForm.iso22716UploadFileUrl = thirdCGMP.file.url;
            thirdFactoryForm.cgmpUploadFileUrl = '';
          }
        }
        thirdFactoryForm.iso22716ExpireDate = !thirdCGMP.isCGMP
          ? thirdCGMP.ISOExpiredDate.replace(/-/g, '')
          : '';
      } else {
        if (formData.thirdAddressKo) {
          thirdFactoryForm.type = 'ADD';
          thirdFactoryForm.companyId = user.companyId;
          thirdFactoryForm.seq = 3;
          secondFactoryForm.manufacturerId = manufacturer.manufacturerId;
          thirdFactoryForm.addressKo = formData.thirdAddressKo;
          thirdFactoryForm.addressEn = formData.thirdAddressEn;

          if (thirdCGMP.isCGMP) {
            thirdFactoryForm.cgmpUploadFile = thirdCGMP.file;
          } else {
            thirdFactoryForm.iso22716UploadFile = thirdCGMP.file;
            thirdFactoryForm.iso22716ExpireDate = thirdCGMP.ISOExpiredDate.replace(/-/g, '');
          }
        }
      }

      const pickFactoryFormKey = [
        'addressEn',
        'addressKo',
        'cgmpUploadFileUrl',
        'iso22716ExpireDate',
        'iso22716UploadFileUrl',
      ];

      const updateFormData = {
        company: { companyId: company.companyId, ...companyForm },
        user: omit({ userId: user.userId, ...userForm }, ['password']),
        factories: [
          pick(firstFactoryForm, pickFactoryFormKey),
          pick(secondFactoryForm, pickFactoryFormKey),
          pick(thirdFactoryForm, pickFactoryFormKey),
        ],
      };

      const originFormData = {
        company: pick({ ...company, companyId: company.companyId }, [
          'addressEn',
          'addressKo',
          'ceoNameEn',
          'ceoNameKo',
          'companyId',
          'fax',
          'homepageUrl',
          'tel',
        ]),
        user: pick(user, [
          'deptName',
          'email',
          'mobile',
          'name',
          'positionName',
          'userId',
          'username',
        ]),
        factories: [
          {
            addressEn: originalFirstFactory.addressEn,
            addressKo: originalFirstFactory.addressKo,
            cgmpUploadFileUrl: originalFirstFactory.cgmpUploadFileUrl,
            iso22716ExpireDate: originalFirstFactory.iso22716ExpireDate,
            iso22716UploadFileUrl: originalFirstFactory.iso22716UploadFileUrl,
          },
          {
            ...(originalSecondFactory?.addressEn && { addressEn: originalSecondFactory.addressEn }),
            ...(originalSecondFactory?.addressKo && { addressKo: originalSecondFactory.addressKo }),
            ...(originalSecondFactory?.cgmpUploadFileUrl && {
              cgmpUploadFileUrl: originalSecondFactory.cgmpUploadFileUrl,
            }),
            ...(originalSecondFactory?.iso22716ExpireDate && {
              iso22716ExpireDate: originalSecondFactory.iso22716ExpireDate,
            }),
            ...(originalSecondFactory?.iso22716UploadFileUrl && {
              iso22716UploadFileUrl: originalSecondFactory.iso22716UploadFileUrl,
            }),
          },
          {
            ...(originalThirdFactory?.addressEn && { addressEn: originalThirdFactory.addressEn }),
            ...(originalThirdFactory?.addressKo && { addressKo: originalThirdFactory.addressKo }),
            ...(originalThirdFactory?.cgmpUploadFileUrl && {
              cgmpUploadFileUrl: originalThirdFactory.cgmpUploadFileUrl,
            }),
            ...(originalThirdFactory?.iso22716ExpireDate && {
              iso22716ExpireDate: originalThirdFactory.iso22716ExpireDate,
            }),
            ...(originalThirdFactory?.iso22716UploadFileUrl && {
              iso22716UploadFileUrl: originalThirdFactory.iso22716UploadFileUrl,
            }),
          },
        ],
      };

      if (!isObjectChanged({ object1: originFormData, object2: updateFormData })) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      openAlertModal({
        content: (
          <Typography.Text style={{ fontSize: '14px' }}>정말로 수정하시겠습니까?</Typography.Text>
        ),
        onOk: () => {
          dispatch(
            manuUpdateCompany({
              company: { companyId: company.companyId, ...companyForm },
              user: { userId: user.userId, ...userForm },
              factories: [firstFactoryForm, secondFactoryForm, thirdFactoryForm],
              companyId: company.companyId,
            }),
          );
        },
      });
    }
  };
  const handleClickChangePassword = () => {
    openChangePasswordModal({});
  };

  useEffect(() => {
    if (updateMode) {
      dispatch(getCompany(company.companyId));
      form.setFieldsValue({
        ...company,
        ...user,
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: { name: 'Uploaded File', url: company.bizLicenseEnUrl },
        }),
      });

      // first
      form.setFieldsValue({
        firstAddressKo: originalFirstFactory.addressKo,
        firstAddressEn: originalFirstFactory.addressEn,
      });
      setFirstCGMP({
        isCGMP: originalFirstFactory.cgmpUploadFileUrl !== '',
        file: {
          name: 'Uploaded File.pdf',
          url: originalFirstFactory.cgmpUploadFileUrl || originalFirstFactory.iso22716UploadFileUrl,
        },
        ...(originalFirstFactory.iso22716ExpireDate && {
          ISOExpiredDate: originalFirstFactory.iso22716ExpireDate.replace(
            /(\d{4})(\d{2})(\d{2})/,
            '$1-$2-$3',
          ),
        }),
      });

      // second
      if (originalSecondFactory) {
        form.setFieldsValue({
          secondAddressKo: originalSecondFactory.addressKo,
          secondAddressEn: originalSecondFactory.addressEn,
        });
        setSecondCGMP({
          isCGMP:
            originalSecondFactory.cgmpUploadFileUrl !== '' &&
            originalSecondFactory.cgmpUploadFileUrl !== null,
          file: {
            name: 'Uploaded File.pdf',
            url:
              originalSecondFactory.cgmpUploadFileUrl ||
              originalSecondFactory.iso22716UploadFileUrl,
          },
          ...(originalSecondFactory.iso22716ExpireDate && {
            ISOExpiredDate: originalSecondFactory.iso22716ExpireDate.replace(
              /(\d{4})(\d{2})(\d{2})/,
              '$1-$2-$3',
            ),
          }),
        });
      }

      // third
      if (originalThirdFactory) {
        form.setFieldsValue({
          thirdAddressKo: originalThirdFactory.addressKo,
          thirdAddressEn: originalThirdFactory.addressEn,
        });
        setThirdCGMP({
          isCGMP:
            originalThirdFactory.cgmpUploadFileUrl !== '' &&
            originalThirdFactory.cgmpUploadFileUrl !== null,
          file: {
            name: 'Uploaded File.pdf',
            url:
              originalThirdFactory.cgmpUploadFileUrl || originalThirdFactory.iso22716UploadFileUrl,
          },
          ...(originalThirdFactory.iso22716ExpireDate && {
            ISOExpiredDate: originalThirdFactory.iso22716ExpireDate.replace(
              /(\d{4})(\d{2})(\d{2})/,
              '$1-$2-$3',
            ),
          }),
        });
      }
    }
  }, []);

  useUpdateEffect(() => {
    if (company) {
      form.setFieldsValue({
        ...(company.bizLicenseUrl && {
          bizLicenseFile: { name: 'Uploaded File', url: company.bizLicenseUrl },
        }),
        ...(company.bizLicenseEnUrl && {
          bizLicenseEnFile: {
            name: 'Uploaded File',
            url: company.bizLicenseEnUrl,
          },
        }),
      });
    }
  }, [company]);

  useEffect(() => {
    if (!updateMode && process.env.NODE_ENV === 'development') {
      form.setFieldsValue({
        companyNameKo: '씨디알아이',
        companyNameEn: 'CDRI',
        addressKo: '서울시 역삽동',
        addressEn: 'Jongro-gu, Seoul',
        ceoNameKo: 'cdri',
        ceoNameEn: 'cdri',
        tel: '0211112222',
        fax: '0211112222',
        homepageUrl: 'http://www.cdri.pro',
        bizNumber: '111-11-11120',
        password: '1111',
        passwordConfirm: '1111',
        name: 'CDRI',
        deptName: '서비스개발팀',
        positionName: '개발자',
        email: 'cdri@cdri.pro',
        mobile: '01012345678',
        isEmailVerified: false,
        isPhoneVerified: false,
        isSmsAgreed: true,
        isEmailAgreed: true,
      });
    }
  }, []);

  return (
    <>
      <CGMPModal cGMP={previewedCGMP} onOk={onChangeCGMP} onClose={handleCloseCGMPModal} />
      <ManuRegisterForm
        tab={tab}
        onChangeTab={onChangeTab}
        updateMode={updateMode}
        form={form}
        manufacturers={manufacturers}
        firstCGMP={firstCGMP}
        secondCGMP={secondCGMP}
        thirdCGMP={thirdCGMP}
        originalSecondFactory={originalSecondFactory}
        originalThirdFactory={originalThirdFactory}
        submitLoading={submitLoading}
        onClickChangePassword={handleClickChangePassword}
        onClickAttachCGMP={setPreviewedCGMP}
        onSubmit={onSubmit}
        bizLicenseEnUrl={company?.bizLicenseEnUrl}
      />
    </>
  );
};

export default ManuRegisterFormContainer;
