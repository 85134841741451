import { Button, Descriptions, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Chip from 'components/ui/Tags/Chip';
import path from 'lib/path';
import palette from 'lib/styles/palette';
import { RootState } from 'modules';
import { addIngredientToBox } from 'modules/material/ingredient';
import { LanguageCode, RegulationType } from 'service/brand/formulaScreening/formulaScreening';
import { useIngredientDetail } from 'service/material/ingredient';
import Tags from 'components/ui/Tags';
import { CountryLawRegulation, RegulationCategoryType } from 'types/material/ingredient';

const IngredientDetail = ({ ingredientId }: { ingredientId: number }) => {
  const {
    ingredientDetail,
    getIngredientDetailLoading,
    ingredientMaterialCount,
    getIngredientMaterialCountLoading,
  } = useIngredientDetail(ingredientId);

  const dispatch = useDispatch();
  const [selectedCountryCode, setSelectedCountryCode] = useState<string>();
  const navigation = useNavigate();

  const { ingredientIds } = useSelector(
    ({ ingredient }: RootState) => ({
      ingredientIds: ingredient.ingredientIds,
    }),
    shallowEqual,
  );

  const {
    nameKo,
    nameCn,
    nameEn,
    nameJp,
    inciName,
    oldName,
    inciOldName,
    casNos,
    ecNos,
    functions,
    ewg,
    origins,
    definition,
    countryLawRegulations,
  } = { ...ingredientDetail };
  const countryInfos =
    countryLawRegulations?.filter((item) => item.country.code === selectedCountryCode) || [];

  useEffect(() => {
    if (
      ingredientDetail?.countryLawRegulations &&
      ingredientDetail.countryLawRegulations.length > 0
    ) {
      setSelectedCountryCode(ingredientDetail.countryLawRegulations[0].country.code);
    }
  }, [ingredientDetail]);

  return (
    <Container>
      <Spin spinning={getIngredientDetailLoading || getIngredientMaterialCountLoading}>
        <Typography.Text
          type="TITLE_1"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {nameKo?.standardName.name || '-'}
        </Typography.Text>
        <Typography.Text
          type="TITLE_2"
          gutter={{ top: 4 }}
          color="GRAY70"
          style={{
            wordBreak: 'break-all',
          }}
        >
          {inciName && inciName.length > 0 ? inciName[0].name : '-'}
        </Typography.Text>
        <Flex gap={8} align="center" gutter={{ top: 16, bottom: 16 }}>
          {ingredientMaterialCount > 0 && (
            <MoveToRawMaterialButton
              onClick={() =>
                navigation(`${path.material.rawMaterial.search}?inciName=${inciName?.[0].name}`)
              }
            >
              해당 성분을 포함하는 원료 보기
            </MoveToRawMaterialButton>
          )}
          <Button
            icon={<Icon name="plus" color="PRIMARY50" size={18} />}
            style={{ display: 'flex', alignItems: 'center', gap: 4 }}
            onClick={() => {
              const isSelected = ingredientId && ingredientIds.includes(ingredientId);

              if (isSelected) {
                message.warning('이미 추가된 성분입니다.');
              } else if (ingredientIds.length === 3) {
                message.warning('최대 3개까지 담을 수 있습니다.');
              } else {
                if (!ingredientId) return;
                dispatch(addIngredientToBox(ingredientId));
              }
            }}
          >
            <Typography.Text type="BODY_2">비교함 담기</Typography.Text>
          </Button>
        </Flex>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="국문명">{nameKo?.standardName.name || '-'}</Descriptions.Item>
          <Descriptions.Item label="구명칭">
            {oldName
              ?.filter((item) => item.languageCode === LanguageCode.KO)
              .map((item) => item.name)
              .join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="INCI Name">
            {inciName?.map((item) => item.name).join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="구 INCI Name">
            {inciOldName?.map((item) => item.name).join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="영문명/이명">
            <Typography.Text color="SLATE_GRAY70" type="BODY_2" gutter={{ bottom: 8 }}>
              {nameEn?.standardName.name}
            </Typography.Text>
            {nameEn?.aliasNames?.map((item) => (
              <Typography.Text color="SLATE_GRAY60" type="BODY_2" key={item.ingredientNameId}>
                {item.name}
              </Typography.Text>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="중문명/이명">
            <Typography.Text color="SLATE_GRAY70" type="BODY_2" gutter={{ bottom: 8 }}>
              {nameCn?.standardName.name}
            </Typography.Text>
            {nameCn?.aliasNames?.map((item) => (
              <Typography.Text color="SLATE_GRAY60" type="BODY_2" key={item.ingredientNameId}>
                {item.name}
              </Typography.Text>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="일문명/이명">
            <Typography.Text color="SLATE_GRAY70" type="BODY_2" gutter={{ bottom: 8 }}>
              {nameJp?.standardName.name}
            </Typography.Text>
            {nameJp?.aliasNames?.map((item) => (
              <Typography.Text color="SLATE_GRAY60" type="BODY_2" key={item.ingredientNameId}>
                {item.name}
              </Typography.Text>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="CAS No.">
            {casNos?.map((item) => item.casNo).join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="EC No.">
            {ecNos?.map((item) => item.ecNo).join(', ')}
          </Descriptions.Item>
          <Descriptions.Item label="Function">
            <Flex gap={8} align="center" wrap="true">
              {functions?.map((item) => (
                <Chip color="PRIMARY30" key={item.ingredientFunctionId}>
                  {item.function}
                </Chip>
              ))}
            </Flex>
          </Descriptions.Item>
          <Descriptions.Item label="EWG">
            {ewg && (
              <EWG scoreMax={ewg.scoreMax}>
                {ewg.scoreMin === ewg.scoreMax ? ewg.scoreMax : `${ewg.scoreMin}-${ewg.scoreMax}`}
              </EWG>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="기원">
            <Flex gap={8} align="center" wrap="true">
              {origins?.map((item) => (
                <Chip color="GRAY30" key={item.ingredientOriginId}>
                  {item.name}
                </Chip>
              ))}
            </Flex>
          </Descriptions.Item>
          <Descriptions.Item label="정의">
            {definition?.find((item) => item.languageCode === LanguageCode.KO)?.definition}
          </Descriptions.Item>
        </Descriptions>
        {countryLawRegulations && countryLawRegulations.length > 0 && (
          <>
            <Typography.Text type="TITLE_1" gutter={{ top: 32, bottom: 16 }}>
              국가 규제사항
            </Typography.Text>
            <Flex gap={8} wrap="true" gutter={{ bottom: 24 }}>
              {countryLawRegulations
                .reduce((acc, cur) => {
                  if (!acc.find((item) => item.code === cur.country.code)) {
                    acc.push({
                      nameKo: cur.country.nameKo,
                      code: cur.country.code,
                    });
                  }
                  return acc;
                }, [] as { nameKo: string; code: string }[])
                .map(({ code, nameKo }) => (
                  <CountryTab
                    key={code}
                    active={code === selectedCountryCode}
                    onClick={() => setSelectedCountryCode(code)}
                  >
                    {nameKo}
                  </CountryTab>
                ))}
            </Flex>
            {countryInfos.length > 0 && (
              <StyledTable>
                <thead>
                  <tr>
                    <Th style={{ width: 200 }}>규제명</Th>
                    <Th style={{ width: 280 }}>고시원료명</Th>
                    <Th style={{ width: 560 }}>제한사항</Th>
                  </tr>
                </thead>
                <tbody>
                  {countryInfos
                    ?.reduce((acc, cur) => {
                      if (!acc.find((item) => item.country.code === cur.country.code)) {
                        acc.push(cur);
                      }
                      return acc;
                    }, [] as CountryLawRegulation[])
                    .map(({ ingredientLawRegulations, groupingLawRegulations, ingredientId }) => {
                      const filteredRegulations = ingredientLawRegulations.map((item) => {
                        const hasBothCategory = [
                          RegulationCategoryType.C_LABEL,
                          RegulationCategoryType.C_REGULATION,
                        ].every((category) =>
                          item.regulations?.some((reg) => reg.category === category),
                        );
                        const onlyLabelRegulations = item.regulations?.filter(
                          (item) =>
                            item.category !== RegulationCategoryType.C_LABEL &&
                            item.category !== RegulationCategoryType.C_REGULATION,
                        );
                        const regulation = item.regulations?.find(
                          ({ category }) => category === RegulationCategoryType.C_REGULATION,
                        );
                        const labelRegulation = item.regulations?.find(
                          ({ category }) => category === RegulationCategoryType.C_LABEL,
                        );

                        return {
                          ...item,
                          regulations: hasBothCategory
                            ? [
                                ...(onlyLabelRegulations || []),
                                {
                                  ...regulation,
                                  regulationsOfLanguage: [
                                    ...(labelRegulation
                                      ? labelRegulation.regulationsOfLanguage
                                      : []),
                                    ...(regulation ? regulation.regulationsOfLanguage : []),
                                  ],
                                },
                              ]
                            : item.regulations,
                        };
                      });

                      return (
                        <React.Fragment key={ingredientId}>
                          {filteredRegulations.map((ingredientLaw, idx) => {
                            return (
                              <React.Fragment key={ingredientLaw.ingredientLawId}>
                                <tr style={{ width: '160px' }}>
                                  <Td
                                    rowSpan={
                                      ingredientLaw.regulations
                                        ? ingredientLaw.regulations?.length + 1
                                        : 1
                                    }
                                  >
                                    {ingredientLaw.law.name}
                                  </Td>
                                </tr>

                                {ingredientLaw?.regulations?.map((regulation, idx) => {
                                  const isContainColorant =
                                    (regulation.category === RegulationCategoryType.C_LABEL ||
                                      regulation.category ===
                                        RegulationCategoryType.C_REGULATION) &&
                                    regulation.type !== RegulationType.CAUTION;
                                  return (
                                    <tr
                                      key={`${ingredientLaw.law.lawId}-${
                                        regulation?.regulationId || idx
                                      }`}
                                    >
                                      <Td width={280}>
                                        {regulation?.notifiedIngredientName || '-'}
                                      </Td>
                                      <Td width={280} style={{ borderRight: 'none' }}>
                                        <div>
                                          <Flex align="center" gap={4}>
                                            <Tags.Mark
                                              color={
                                                regulation?.type?.toUpperCase() ===
                                                RegulationType.LIMIT
                                                  ? 'green'
                                                  : regulation?.type?.toUpperCase() ===
                                                    RegulationType.CAUTION
                                                  ? 'yellow'
                                                  : 'red'
                                              }
                                              style={{ margin: '0 0 8px 0' }}
                                            >
                                              {regulation?.type?.toUpperCase() ===
                                              RegulationType.LIMIT
                                                ? '제한'
                                                : regulation?.type?.toUpperCase() ===
                                                  RegulationType.CAUTION
                                                ? '주의'
                                                : '금지'}
                                            </Tags.Mark>
                                            {isContainColorant && (
                                              <Tags.Mark
                                                color={'purple'}
                                                style={{ margin: '0 0 8px 0' }}
                                              >
                                                색소
                                              </Tags.Mark>
                                            )}
                                          </Flex>
                                          {regulation.regulationsOfLanguage
                                            .filter(({ languageCode }) => languageCode === 'KO')
                                            .map((item) => (
                                              <>
                                                {item.limitedMatter}
                                                <br />
                                              </>
                                            ))}
                                        </div>
                                      </Td>
                                    </tr>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                          {groupingLawRegulations &&
                            groupingLawRegulations.map((groupingLawRegulation) => {
                              const isGroupLimit =
                                groupingLawRegulation.groupingLawRegulations[0].regulation?.type ===
                                'LIMIT';

                              return (
                                <tr>
                                  <Td style={{ flex: '0 0 200px' }}>
                                    {groupingLawRegulation.law.name}
                                  </Td>
                                  <Td
                                    style={{
                                      flex: '0 0 280px',
                                      fontWeight: 500,
                                    }}
                                  >
                                    {groupingLawRegulation.groupingLawRegulations[0].regulation
                                      ?.notifiedIngredientName || '-'}
                                  </Td>
                                  <Td style={{ flex: '0 0 560px' }}>
                                    <div>
                                      <Tags.Mark
                                        color={isGroupLimit ? 'green' : 'red'}
                                        style={{ margin: '0 0 8px 0' }}
                                      >
                                        {isGroupLimit ? '제한' : '금지'}
                                      </Tags.Mark>
                                      {groupingLawRegulation.groupingLawRegulations[0].regulation?.regulationsOfLanguage.find(
                                        (item) => item.languageCode === 'KO',
                                      )?.limitedMatter || ''}
                                    </div>
                                  </Td>
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </StyledTable>
            )}
          </>
        )}
      </Spin>
    </Container>
  );
};

const Container = styled.div`
  .ant-descriptions-bordered .ant-descriptions-item-label,
  .ant-descriptions-bordered .ant-descriptions-item-content,
  .ant-descriptions-bordered .ant-descriptions-row {
    border-color: ${palette.GRAY30};
  }

  .ant-descriptions.ant-descriptions-bordered > .ant-descriptions-view {
    border-left: none;
    border-right: none;
    border-top: 2px solid ${palette.PRIMARY50};
    border-radius: 0;
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-label {
    padding: 10px 16px;
    width: 168px;
    font-weight: 500;
    font-size: 14px;
    color: ${palette.SLATE_GRAY70};
  }

  .ant-descriptions.ant-descriptions-bordered
    > .ant-descriptions-view
    .ant-descriptions-row
    > .ant-descriptions-item-content {
    padding: 10px 16px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    color: ${palette.SLATE_GRAY70};
  }
`;

const MoveToRawMaterialButton = styled.div`
  height: 44px;
  padding: 12px 16px;
  border-radius: 4px;
  background: ${palette.SLATE_GRAY70};
  cursor: pointer;
  color: #fff;
  font-size: 14px;
`;

const CountryTab = styled.div<{ active: boolean }>`
  padding: 9px 15px;
  border-radius: 4px;
  width: fit-content;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;

  ${({ active }) =>
    active
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background: #fff;
          color: ${palette.SLATE_GRAY60};
        `}
`;

const EWG = styled.div<{ scoreMax: number }>`
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: center;
  align-items: center;
  border-radius: 6px 12px 12px 12px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;

  ${({ scoreMax }) =>
    scoreMax <= 2
      ? css`
          background: #00b32d;
        `
      : scoreMax <= 6
      ? css`
          background: #fdb900;
        `
      : css`
          background: #fb2f2f;
        `};
`;

const StyledTable = styled.table`
  border-top: 2px solid ${palette.PRIMARY50};
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 12px 16px;
  background-color: ${palette.GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }
`;

const Td = styled.td`
  align-items: center;
  padding: 12px 16px;
  background-color: ${palette.ETC_WHITE};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  white-space: pre-wrap;
`;

export default IngredientDetail;
