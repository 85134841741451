import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { getTextColorByRule, ruleCategories } from 'lib/artworkScreening';
import palette from 'lib/styles/palette';
import ArtworkIngredientListItem from './ArtworkIngredientListItem';
import { ArtworkIngredient } from 'types/brand/artworkScreening/artworkScreening';

interface ArtworkIngredientListProps {
  targetRect: DOMRect | undefined;
  setTargetRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  artworkIngredients: ArtworkIngredient[];
  formulaScreeningId: number;
  setClickedIngredientArea: Dispatch<
    SetStateAction<{
      type?: 'formulaScreeningAnalyzeItemId' | 'formulaScreeningArtworkImageIngredientId';
      id?: number;
    }>
  >;
  errorCount: number;
  setArtworkIngredients: Dispatch<SetStateAction<ArtworkIngredient[]>>;
  selectedCountryCodes: string[];
  handleReport: (temp: ArtworkIngredient[]) => void;
}

const ArtworkIngredientList = ({
  targetRect,
  setTargetRect,
  artworkIngredients,
  formulaScreeningId,
  setClickedIngredientArea,
  errorCount,
  setArtworkIngredients,
  selectedCountryCodes,
  handleReport,
}: ArtworkIngredientListProps) => {
  /**
   * textRect: 칩 텍스트의 위치 정보 getBoundingClientRect. 텍스트가 긴 경우 말줄임표 처리 및 툴팁 표시
   * openCollapseId: 열려있는 성분 아이디
   */
  const [textRect, setTextRect] = useState<DOMRect>();
  const [openCollapseId, setOpenCollapseId] = useState<number>();

  const ruleContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 스크롤 했을때 툴팁 사라지고 초기화
    const handleScroll = () => {
      setTargetRect(undefined);
      setTextRect(undefined);
    };

    ruleContainerRef.current?.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      ruleContainerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [targetRect, textRect]);

  return (
    <Container>
      <Category />
      <IngredientListWrapper>
        <Header>
          <Typography.Text color="SLATE_GRAY70" medium>
            Artwork 전성분 리스트
          </Typography.Text>
          <Flex gap={4}>
            <Typography.Text color="SLATE_GRAY70" medium>
              진단 항목
            </Typography.Text>
            <Typography.Text color="SLATE_GRAY70" medium>
              :
            </Typography.Text>
            <Typography.Text color="SLATE_GRAY70" medium>
              {errorCount ? '오류 성분' : '오류 성분 없음'}
            </Typography.Text>
            {errorCount !== 0 && (
              <Flex gap={2}>
                <Typography.Text color="MESSAGE_ERROR" medium inline>
                  {errorCount}
                </Typography.Text>
                <Typography.Text color="SLATE_GRAY70" medium inline>
                  건
                </Typography.Text>
              </Flex>
            )}
          </Flex>
        </Header>
        <Content ref={ruleContainerRef}>
          {artworkIngredients.map((artworkIngredient, idx) => (
            <ArtworkIngredientListItem
              {...artworkIngredient}
              key={artworkIngredient.id}
              formulaScreeningId={formulaScreeningId}
              idx={idx}
              openCollapseId={openCollapseId}
              setOpenCollapseId={setOpenCollapseId}
              textRect={textRect}
              setTextRect={setTextRect}
              targetRect={targetRect}
              setTargetRect={setTargetRect}
              setClickedIngredientArea={setClickedIngredientArea}
              setArtworkIngredients={setArtworkIngredients}
              selectedCountryCodes={selectedCountryCodes}
              artworkIngredients={artworkIngredients}
              handleReport={handleReport}
            />
          ))}
        </Content>
      </IngredientListWrapper>
    </Container>
  );
};

const Container = styled.div`
  flex: 0 0 628px;
  max-width: 628px;
  word-break: break-word;
  position: relative;
`;

const IngredientListWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${palette.GRAY30};
  margin-top: 8px;
`;

const Header = styled(Flex)`
  gap: 8;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  padding: 16px 23px;
  background: ${palette.SLATE_GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
`;

const Content = styled.div`
  padding: 24px;
  height: 736px;
  overflow-y: scroll;
`;

const Category = () => {
  return (
    <Flex gap={16} justify="flex-end" style={{ height: 20 }}>
      {ruleCategories.map(({ type, text }) => (
        <Flex key={text} gap={4}>
          <ColorDot color={getTextColorByRule(type)} />
          <Typography.Text medium color="SLATE_GRAY70" type="BODY_2">
            {text}
          </Typography.Text>
        </Flex>
      ))}
    </Flex>
  );
};

const ColorDot = styled.div<{ color: keyof typeof palette }>`
  width: 6px;
  height: 6px;
  background: ${({ color }) => palette[color]};
  border-radius: 50%;
`;

export default ArtworkIngredientList;
