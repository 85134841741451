import { message } from 'antd';
import styled, { css } from 'styled-components';
import { useEffect } from 'react';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';
import { ArtworkIngredient } from 'types/brand/artworkScreening/artworkScreening';

interface CountryListProps {
  availableCountryCodes: string[];
  selectedCountryCodes: string[];
  selectedPerformanceCountryCodes: string[];
  countryCodeToNameMap: Record<string, string>;
  setSelectedCountryCodes: (countryCodes: string[]) => void;
  setSelectedPerformanceCountryCodes: (countryCodes: string[]) => void;
  artworkIngredients: ArtworkIngredient[];
  isAdmin?: boolean;
}

const CountryList = ({
  availableCountryCodes,
  selectedCountryCodes,
  selectedPerformanceCountryCodes,
  countryCodeToNameMap,
  setSelectedCountryCodes,
  setSelectedPerformanceCountryCodes,
  artworkIngredients,
  isAdmin,
}: CountryListProps) => {
  const { openAlertModal } = useModal();

  const handleClickCountry = ({
    isSelected,
    countryCode,
  }: {
    isSelected: boolean;
    countryCode: string;
  }) => {
    const newSelectedCountryCodes = isSelected
      ? selectedCountryCodes.filter((code) => code !== countryCode)
      : [...selectedCountryCodes, countryCode];
    const newSelectedPerformanceCountryCodes = isSelected
      ? selectedPerformanceCountryCodes.filter((code) => code !== countryCode)
      : [...selectedPerformanceCountryCodes, countryCode];

    if (newSelectedCountryCodes.length === 0) {
      message.warning('최소 1개 이상의 국가를 선택해 주세요.');
      return;
    }

    // 하나라도 수정된 성분 있을때 초기화 모달 띄우기
    const isChanged =
      artworkIngredients.filter(
        (item) => item.errors.length > 0 && item.errors.some(({ isUpdate }) => isUpdate),
      ).length > 0;

    if (isChanged) {
      openAlertModal({
        width: 400,
        content:
          '선택한 국가를 변경하면 변경한 국가 구성에 따라\n진단 항목이 변경되고, 적용 리스트가 초기화됩니다.',
        okText: '변경',
        onOk: () => {
          setSelectedCountryCodes(newSelectedCountryCodes);
          setSelectedPerformanceCountryCodes(newSelectedCountryCodes);
        },
      });
    } else {
      setSelectedCountryCodes(newSelectedCountryCodes);
      setSelectedPerformanceCountryCodes(newSelectedPerformanceCountryCodes);
    }
  };

  useEffect(() => {
    setSelectedPerformanceCountryCodes(selectedCountryCodes);
  }, [selectedCountryCodes]);

  return (
    <CountrySelectWrapper>
      <Flex gap={8}>
        <Typography.Text>🌎</Typography.Text>
        <Typography.Text semiBold color="SLATE_GRAY70">
          국가별 전성분 규칙에 맞게 국가를 선택하실 수 있습니다.
        </Typography.Text>
      </Flex>
      <Flex wrap="true" gap={8} gutter={{ top: 16 }}>
        {(isAdmin
          ? availableCountryCodes.filter((code) => code !== 'ETC' && code !== 'CN' && code !== 'JP')
          : availableCountryCodes
        ).map((countryCode) => {
          const isSelected = selectedCountryCodes.includes(countryCode);
          const countryNameKo = countryCodeToNameMap[countryCode];

          return (
            <CountryItem
              key={countryCode}
              gap={8}
              isSelected={isSelected}
              onClick={() => handleClickCountry({ isSelected, countryCode })}
            >
              <Icon name={isSelected ? 'checkCircleActived' : 'checkCircleInactived'} size={18} />
              <Typography.Text
                type="BODY_2"
                medium={isSelected}
                color={isSelected ? 'GRAY90' : 'SLATE_GRAY50'}
              >
                {countryNameKo || '-'}
              </Typography.Text>
            </CountryItem>
          );
        })}
      </Flex>
    </CountrySelectWrapper>
  );
};

const CountrySelectWrapper = styled.div`
  padding: 24px 32px;
  background-color: ${palette.ETC_BG_BLUE};
  border-radius: 8px;
  min-height: 190px;
`;

const CountryItem = styled(Flex)<{ isSelected: boolean }>`
  background-color: #fff;
  width: 156px;
  border-radius: 4px;
  padding: 13px 15px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 1px solid ${palette.PRIMARY50};
          box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
        `
      : css`
          border: 1px solid ${palette.GRAY30};
        `};
`;

export default CountryList;
