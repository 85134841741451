import { Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import palette from 'lib/styles/palette';
import {
  CountryLawRegulation,
  Regulation,
  RegulationCategoryType,
} from 'types/material/ingredient';
import Tags from 'components/ui/Tags';
import { Law, RegulationType } from 'service/brand/formulaScreening/formulaScreening';
import path from 'lib/path';

const ModalHeader = styled(Flex)`
  padding: 16px 24px;
  border-bottom: 1px solid ${palette.GRAY20};
  height: 58px;
`;

const ModalBody = styled.div`
  padding: 24px 40px 32px;
  height: 502px;
  overflow-y: scroll;
`;

const Tab = styled.div<{ isActive: boolean; label: string }>`
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  ${({ isActive }) =>
    isActive
      ? css`
          border: 1px solid ${palette.SLATE_GRAY20};
          background-color: ${palette.SLATE_GRAY20};
          color: ${palette.SLATE_GRAY70};
          font-weight: 500;
          cursor: default;
        `
      : css`
          border: 1px solid ${palette.GRAY40};
          background-color: ${palette.ETC_WHITE};
          color: ${palette.SLATE_GRAY60};
          cursor: pointer;
        `};

  &::after {
    content: '${({ label }) => label}';
    font-weight: bold;
    display: block;
    height: 0;
    visibility: hidden;
  }
`;

const StyledTable = styled.div`
  margin-top: 24px;
  border-top: 2px solid ${palette.PRIMARY50};
`;

const HeaderItem = styled.th`
  padding: 12px 16px;
  background-color: ${palette.GRAY10};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  font-weight: 500;

  &:last-child {
    border-right: none;
  }
`;

const TableBody = styled.tbody``;

const BodyItem = styled.td`
  align-items: center;
  padding: 12px 16px;
  background-color: ${palette.ETC_WHITE};
  border-bottom: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  color: ${palette.SLATE_GRAY70};
  font-size: 14px;
  white-space: pre-wrap;
`;

const FormulaScreeningCountryProhibitInfosModal = ({
  defaultCountryCode,
  countryInfos,
  onClose,
  maskStyle,
  ingredientInfo,
}: {
  defaultCountryCode?: string;
  countryInfos: CountryLawRegulation[];
  onClose: () => void;
  ingredientInfo?: {
    ingredientName: string;
    ingredientId: number;
  };
  maskStyle?: React.CSSProperties;
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(
    defaultCountryCode || countryInfos[0].country.code,
  );

  const selectedCountryInfos = useMemo(() => {
    return countryInfos?.filter(({ country: { code } }) => code === selectedCountryCode);
  }, [selectedCountryCode, countryInfos]);

  return (
    <Modal
      open={countryInfos.length > 0}
      width={896}
      onCancel={onClose}
      footer={null}
      centered
      styles={{ body: { padding: 0, height: 560 }, mask: maskStyle }}
      closable={false}
    >
      <ModalHeader justify="space-between" align="center">
        <Typography.Text type="TITLE_2" semiBold>
          성분 규제사항
          {ingredientInfo?.ingredientName && (
            <>
              {' '}
              -
              <Typography.Text
                type="TITLE_2"
                color="GRAY90"
                inline
                underline
                style={{ fontWeight: 600 }}
                onClick={() => {
                  window.open(
                    `${path.material.ingredient.detail}/${ingredientInfo?.ingredientId}?newpage=true`,
                  );
                }}
              >
                {ingredientInfo?.ingredientName}
              </Typography.Text>
            </>
          )}
        </Typography.Text>
        <Icon name="close" size={24} onClick={onClose} />
      </ModalHeader>
      <ModalBody>
        <Flex gap={8} wrap="true">
          {countryInfos
            .reduce((acc, cur) => {
              if (!acc.find((item) => item.code === cur.country.code)) {
                acc.push({
                  nameKo: cur.country.nameKo,
                  code: cur.country.code,
                });
              }
              return acc;
            }, [] as { nameKo: string; code: string }[])
            .map(({ code, nameKo }) => (
              <Tab
                label={nameKo}
                key={code}
                isActive={code === selectedCountryCode}
                onClick={() => code !== selectedCountryCode && setSelectedCountryCode(code)}
              >
                {nameKo}
              </Tab>
            ))}
        </Flex>
        {selectedCountryInfos.length > 0 && (
          <StyledTable>
            <thead>
              <HeaderItem style={{ width: '160px' }}>규제명</HeaderItem>
              <HeaderItem style={{ width: '280px' }}>고시원료명</HeaderItem>
              <HeaderItem style={{ width: '376px' }}>제한사항</HeaderItem>
            </thead>
            <TableBody>
              {selectedCountryInfos
                ?.reduce((acc, cur) => {
                  if (!acc.find((item) => item.country.code === cur.country.code)) {
                    acc.push(cur);
                  }
                  return acc;
                }, [] as CountryLawRegulation[])
                .map(({ ingredientLawRegulations, groupingLawRegulations, ingredientId }) => {
                  const combinedRegulations: {
                    type: string;
                    law: Law;
                    regulations: any[];
                    lawIngredientWhitelistId?: number;
                  }[] = [
                    ...ingredientLawRegulations.map((regulation) => ({
                      type: 'ingredient',
                      law: regulation.law,
                      regulations: regulation.regulations || [],
                      lawIngredientWhitelistId: regulation.lawIngredientWhitelistId,
                    })),
                    ...groupingLawRegulations.map((groupingRegulation) => ({
                      type: 'grouping',
                      law: groupingRegulation.law,
                      regulations:
                        groupingRegulation.groupingLawRegulations.map((reg) => reg.regulation) ||
                        [],
                    })),
                  ];

                  const groupedByLaw = combinedRegulations.reduce((acc, cur, idx) => {
                    const existingGroup = acc.find((group) => group.law.lawId === cur.law.lawId);
                    if (existingGroup) {
                      existingGroup.regulations.push(...cur.regulations);
                    } else {
                      acc.push({ law: cur.law, regulations: [...cur.regulations] });
                    }

                    return acc;
                  }, [] as { law: Law; regulations: any[]; lawIngredientWhitelistId?: number }[]);
                  const filteredRegulations = groupedByLaw.map((item) => {
                    const hasBothCategory = [
                      RegulationCategoryType.C_LABEL,
                      RegulationCategoryType.C_REGULATION,
                    ].every((category) =>
                      item.regulations.some((reg) => reg.category === category),
                    );
                    const onlyLabelRegulations = item.regulations.filter(
                      (item) =>
                        item.category !== RegulationCategoryType.C_LABEL &&
                        item.category !== RegulationCategoryType.C_REGULATION,
                    );
                    const regulation = item.regulations.find(
                      ({ category }) => category === RegulationCategoryType.C_REGULATION,
                    );
                    const labelRegulation = item.regulations.find(
                      ({ category }) => category === RegulationCategoryType.C_LABEL,
                    );

                    return {
                      ...item,
                      regulations: hasBothCategory
                        ? [
                            ...onlyLabelRegulations,
                            {
                              ...regulation,
                              regulationsOfLanguage: [
                                ...labelRegulation.regulationsOfLanguage,
                                ...regulation.regulationsOfLanguage,
                              ],
                            },
                          ]
                        : item.regulations,
                    };
                  });

                  return (
                    <React.Fragment key={ingredientId}>
                      <React.Fragment key={ingredientId}>
                        {filteredRegulations.map(({ law, regulations }) => (
                          <React.Fragment key={law.lawId}>
                            <tr>
                              <BodyItem
                                rowSpan={regulations.length + 1 || 1}
                                style={{ width: '160px' }}
                              >
                                {law.name}
                              </BodyItem>
                            </tr>
                            {regulations.map((regulation: Regulation, idx) => {
                              const isContainColorant =
                                (regulation.category === RegulationCategoryType.C_LABEL ||
                                  regulation.category === RegulationCategoryType.C_REGULATION) &&
                                regulation.type !== RegulationType.CAUTION;
                              return (
                                <tr key={`${law.lawId}-${regulation?.regulationId || idx}`}>
                                  <BodyItem width={280}>
                                    {regulation?.notifiedIngredientName || '-'}
                                  </BodyItem>
                                  <BodyItem width={280} style={{ borderRight: 'none' }}>
                                    <div>
                                      <Flex align="center" gap={4}>
                                        <Tags.Mark
                                          color={
                                            regulation?.type?.toUpperCase() === RegulationType.LIMIT
                                              ? 'green'
                                              : regulation?.type?.toUpperCase() ===
                                                RegulationType.CAUTION
                                              ? 'yellow'
                                              : 'red'
                                          }
                                          style={{ margin: '0 0 8px 0' }}
                                        >
                                          {regulation?.type?.toUpperCase() === RegulationType.LIMIT
                                            ? '제한'
                                            : regulation?.type?.toUpperCase() ===
                                              RegulationType.CAUTION
                                            ? '주의'
                                            : '금지'}
                                        </Tags.Mark>
                                        {isContainColorant && (
                                          <Tags.Mark
                                            color={'purple'}
                                            style={{ margin: '0 0 8px 0' }}
                                          >
                                            색소
                                          </Tags.Mark>
                                        )}
                                      </Flex>
                                      {regulation.regulationsOfLanguage
                                        .filter(({ languageCode }) => languageCode === 'KO')
                                        .map((item) => (
                                          <>
                                            {item.limitedMatter}
                                            <br />
                                          </>
                                        ))}
                                    </div>
                                  </BodyItem>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </StyledTable>
        )}
      </ModalBody>
    </Modal>
  );
};

export default FormulaScreeningCountryProhibitInfosModal;
