// source: https://yeun.github.io/open-color/
const palette = {
  PRIMARY10: '#F5F9FF',
  PRIMARY20: '#F1F6FF',
  PRIMARY30: '#E6F0FF',
  PRIMARY40: '#D7E7FE',
  PRIMARY50: '#3785F7',
  PRIMARY60: '#0854C4',
  PRIMARY70: '#063D8E',
  PRIMARY80: '#04285D',
  PRIMARY90: '#02132C',
  SECONDARY_10: '#FFF6F8',
  SECONDARY_20: '#FFEBEE',
  SECONDARY_30: '#FFD9DB',
  SECONDARY_40: '#FFBAB9',
  SECONDARY_50: '#FF9290',
  SECONDARY_60: '#FB625F',
  SECONDARY_70: '#FB2F2F',
  SECONDARY_80: '#DA2128',
  SECONDARY_90: '#B80213',
  LIGHT_BLUE10: '#F2FBFF',
  LIGHT_BLUE20: '#DCF3FF',
  LIGHT_BLUE30: '#B1E2FF',
  LIGHT_BLUE40: '#00B9E1',
  PURPLE_10: '#F6F2FF',
  PURPLE_20: '#EDE5FF',
  PURPLE_30: '#D4C3FD',
  PURPLE_40: '#AB91EE',
  PURPLE_50: '#8761E7',
  PURPLE_60: '#6A40D3',
  PURPLE_70: '#4E2E9E',
  PURPLE_80: '#341289',
  PURPLE_90: '#210173',
  YELLOW_10: '#FFFBE4',
  YELLOW_20: '#FFF5D1',
  YELLOW_30: '#FFF99D',
  YELLOW_40: '#FFD957',
  YELLOW_50: '#FFC500',
  YELLOW_60: '#FDB900',
  YELLOW_70: '#FF9300',
  YELLOW_80: '#FF7A00',
  YELLOW_90: '#DE5D00',
  GREEN_10: '#F1FFF5',
  GREEN_20: '#E3FBE7',
  GREEN_30: '#B9F5C3',
  GREEN_40: '#63E086',
  GREEN_50: '#02BF65',
  GREEN_60: '#00944E',
  GREEN_70: '#00733D',
  GREEN_80: '#00582E',
  GREEN_90: '#003D20',
  GRAY10: '#F9F9F9',
  GRAY20: '#F5F5F5',
  GRAY30: '#E7E7E7',
  GRAY40: '#D3D3D3',
  GRAY50: '#A9A6A6',
  GRAY60: '#A2A2A2',
  GRAY70: '#7E7E7E',
  GRAY80: '#4A4A4A',
  GRAY90: '#222222',
  SLATE_GRAY10: '#F6F7F9',
  SLATE_GRAY20: '#F1F4F6',
  SLATE_GRAY30: '#EFF1F8',
  SLATE_GRAY40: '#D6D7DC',
  SLATE_GRAY50: '#A9ADB7',
  SLATE_GRAY60: '#9195A5',
  SLATE_GRAY70: '#4E5968',
  SLATE_GRAY80: '#474A57',
  SLATE_GRAY90: '#23282F',
  MESSAGE_ERROR: '#FF4D4F',
  MESSAGE_WARNING: '#FAD716',
  MESSAGE_SUCCESS: '#29BF5F',
  MESSAGE_INFO: '#1890FF',
  MESSAGE_CHECK: '#03CBA4',
  ETC_WHITE: '#FFFFFF',
  ETC_HIGHLIGHTING: '#2CF4F0',
  ETC_BG_BLUE: '#F5F8FB',
  //others
  STIBEE_BLUE: '#9ebaf9',
};

export default palette;
