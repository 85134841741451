import { Button, Spin } from 'antd';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import palette from 'lib/styles/palette';
import { CountryLaw, FormulaScreeningItem } from 'service/brand/formulaScreening/formulaScreening';
import FormulaScreeningTable from './FormulaScreeningTable';
import { useCountryProhibitsUpdatedDate } from 'service/material/ingredient';

const Step3 = ({
  setStep,
  formulaScreeningResult,
  uploadFormulaScreeningJsonLoading,
  selectedCountryLaws,
  selectedDistributorIds,
  selectedSpecialRegionIds,
  method,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formulaScreeningResult?: FormulaScreeningItem | null;
  uploadFormulaScreeningJsonLoading: boolean;
  selectedCountryLaws: CountryLaw[];
  selectedSpecialRegionIds: string[];
  selectedDistributorIds: string[];
  method: number;
}) => {
  const prohibitDataUpdatedDate = useCountryProhibitsUpdatedDate();

  const success = formulaScreeningResult
    ? !formulaScreeningResult.list.some((item) => item?.param.errorCode)
    : undefined;

  const handleReset = () => {
    setStep(1);
  };

  return (
    <Container>
      <Flex gap={16} style={{ marginBottom: 28, marginTop: 56 }}>
        <Icon name="check" color="PRIMARY50" size={92} />
        <Typography.Text type="HEADLINE_1" style={{ color: palette.GRAY90, fontWeight: 300 }}>
          성분 스크리닝을 완료하였습니다.
          <br />
          아래 결과를 확인해 주세요.
        </Typography.Text>
      </Flex>
      {formulaScreeningResult && (
        <>
          <Flex
            style={{ marginTop: 24 }}
            align="center"
            justify={
              success || formulaScreeningResult.list.some((item) => item?.param.errorCode)
                ? 'space-between'
                : 'flex-end'
            }
          >
            <Flex dir="column" align="start" justify="center" gap={4}>
              {success && (
                <Typography.Text type="BODY_2" style={{ display: 'flex', alignItems: 'center' }}>
                  <StatusIcon status="success" />
                  정상적으로 업로드되었습니다.
                </Typography.Text>
              )}
              {formulaScreeningResult.list.some((item) => item?.param.errorCode) && (
                <Typography.Text type="BODY_2" style={{ display: 'flex', alignItems: 'center' }}>
                  <StatusIcon status="warning" />
                  <Typography.Text type="BODY_2" inline>
                    {`표시된 ${method === 1 ? '성분명' : 'INCI Name'}`}
                  </Typography.Text>
                  을 확인해 주세요.
                </Typography.Text>
              )}
              <Typography.Text type="BODY_2" style={{ display: 'flex', alignItems: 'center' }}>
                <StatusIcon status="info" />
                <Typography.Text type="BODY_2" inline>
                  여러개의 성분이 검색된 경우는 실제 어떤 성분이 사용되었는지 확인이 필요합니다.
                </Typography.Text>
              </Typography.Text>
            </Flex>

            <Flex align="center" gap={8}>
              <Tags.Mark color="lightGray">DB 업데이트</Tags.Mark>
              <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline>
                [국가별 성분 규제]
              </Typography.Text>
              <Typography.Text type="SMALL" medium color="SLATE_GRAY70" inline>
                {prohibitDataUpdatedDate}
              </Typography.Text>
            </Flex>
          </Flex>
          <Spin spinning={uploadFormulaScreeningJsonLoading}>
            <FormulaScreeningTable
              formulaScreeningResult={formulaScreeningResult}
              selectedCountryLaws={selectedCountryLaws}
              selectedSpecialRegionIds={selectedSpecialRegionIds}
              selectedDistributorIds={selectedDistributorIds}
            />
          </Spin>
        </>
      )}
      <Flex gap={16} style={{ marginTop: 56 }} justify="center">
        <Button
          htmlType="button"
          style={{
            width: 200,
            height: 56,
            fontSize: 18,
          }}
          type="default"
          onClick={() => setStep(2)}
        >
          이전
        </Button>
        <Button
          style={{
            width: 200,
            height: 56,
            background: palette.PRIMARY50,
            fontSize: 18,
          }}
          htmlType="button"
          type="primary"
          onClick={handleReset}
        >
          다시 작성
        </Button>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .ant-table-wrapper {
    border: 1px solid ${palette.GRAY30};
    border-radius: 4px;
    overflow: visible;
  }
`;

export default Step3;
