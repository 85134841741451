import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';
import { message } from 'antd';

import { Flex } from 'components/ui';
import TooltipIcon from './TooltipIcon';
import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { useModal } from 'hook/useModal';
import {
  ArtworkIngredient,
  Coordinate,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import { getTextColorByErrors } from 'lib/artworkScreening';

interface UpdatedIngredientListProps {
  targetRect?: DOMRect;
  setTargetRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
  errorCount: number;
  artworkIngredients: ArtworkIngredient[];
  updatedArtworkIngredients: ArtworkIngredient[];
  clickedIngredientArea: {
    type?: 'formulaScreeningAnalyzeItemId' | 'formulaScreeningArtworkImageIngredientId';
    id?: number;
  };
  setIngredientCoordinate: Dispatch<SetStateAction<Coordinate[]>>;
  setIsMinimize: Dispatch<SetStateAction<boolean>>;
  itemsRef: React.MutableRefObject<Map<number, HTMLDivElement>>;
  setArtworkIngredients: Dispatch<SetStateAction<ArtworkIngredient[]>>;
}

const UpdatedIngredientList = ({
  targetRect,
  setTargetRect,
  errorCount,
  artworkIngredients,
  updatedArtworkIngredients,
  setArtworkIngredients,
  clickedIngredientArea,
  setIngredientCoordinate,
  setIsMinimize,
  itemsRef,
}: UpdatedIngredientListProps) => {
  const { openAlertModal } = useModal();

  const isContainCheckError = artworkIngredients.some((item) =>
    item.errors.some(({ ruleType }) => ruleType === RuleType.CHECK),
  );

  const updateCount = isContainCheckError
    ? artworkIngredients.filter(
        (item) => item.errors.length > 0 && item.errors.some(({ isUpdate }) => isUpdate),
      ).length
    : artworkIngredients.filter(
        (item) => item.errors.length > 0 && item.errors.every(({ isUpdate }) => isUpdate),
      ).length;

  const initializeUpdateInfo = () => {
    setArtworkIngredients(
      artworkIngredients.map((item) => ({
        ...item,
        errors: item.errors.map((error) => ({ ...error, isUpdate: false })),
        newDirectName: item.errors.reduce((acc, { ruleType }) => {
          acc[ruleType] = '';
          return acc;
        }, {} as Record<RuleType, string>),
        updatedName: '',
      })),
    );
  };

  return (
    <Container>
      <Flex
        gap={4}
        style={{
          padding: '16px 23px',
          background: palette.SLATE_GRAY10,
          borderRadius: '8px 8px 0 0',
          borderBottom: `1px solid ${palette.GRAY30}`,
        }}
      >
        <Typography.Text color="SLATE_GRAY70" medium>
          적용 리스트
        </Typography.Text>
        {errorCount !== 0 && (
          <>
            <Typography.Text color="SLATE_GRAY70" medium>
              :
            </Typography.Text>
            <Flex gap={2}>
              <Typography.Text color="PRIMARY50" medium>
                {updateCount}
              </Typography.Text>
              <Typography.Text color="SLATE_GRAY70" medium>
                /
              </Typography.Text>
              <Typography.Text color="MESSAGE_ERROR" medium>
                {errorCount}
              </Typography.Text>
              <Typography.Text color="SLATE_GRAY70" medium>
                건
              </Typography.Text>
            </Flex>
          </>
        )}
      </Flex>
      <div>
        <Flex
          dir="column"
          gap={16}
          style={{
            padding: 24,
            height: 680,
            overflowY: 'scroll',
            alignItems: 'flex-start',
          }}
        >
          {updatedArtworkIngredients.map(
            (
              { id, coordinates, name, errors, updatedName, newDirectName, imageIngredient },
              idx,
            ) => {
              const color = getTextColorByErrors(errors);
              const ruleTypeMiss = errors.some(({ ruleType }) => ruleType === RuleType.MISS);
              const matchDirectName = Object.values(newDirectName).find(
                (name) => name && name !== '',
              );

              return (
                <div
                  key={idx}
                  ref={(el) => {
                    if (el) {
                      itemsRef.current.set(id, el);
                    } else {
                      itemsRef.current.delete(id);
                    }
                  }}
                >
                  <Typography.Text
                    color={ruleTypeMiss ? 'PRIMARY50' : color}
                    inline
                    onClick={() => {
                      setIngredientCoordinate(coordinates);
                      if (coordinates.length > 0) {
                        setIsMinimize(false);
                      }
                    }}
                    style={{
                      background:
                        clickedIngredientArea.type &&
                        ((clickedIngredientArea.type === 'formulaScreeningAnalyzeItemId' &&
                          clickedIngredientArea.id === id) ||
                          clickedIngredientArea.id ===
                            imageIngredient?.formulaScreeningArtworkImageIngredientId)
                          ? 'linear-gradient(to bottom, transparent 13px, RGBA(44, 244, 241, 0.5) 0%)'
                          : '',
                    }}
                  >
                    {matchDirectName ? matchDirectName : updatedName ? updatedName : name}
                  </Typography.Text>
                </div>
              );
            },
          )}
        </Flex>
        <Flex
          gap={16}
          style={{
            padding: '15px 24px',
            borderTop: `1px solid ${palette.GRAY30}`,
          }}
        >
          <TooltipIcon
            name="copy"
            size={24}
            targetRect={targetRect}
            setTargetRect={setTargetRect}
            onClick={() => {
              navigator.clipboard
                .writeText(
                  updatedArtworkIngredients
                    .map(({ name, newDirectName, updatedName }) => {
                      const matchDirectName = Object.values(newDirectName).find(
                        (name) => name && name !== '',
                      );
                      return matchDirectName ? matchDirectName : updatedName ? updatedName : name;
                    })
                    .join(', '),
                )
                .then(() => {
                  message.success('텍스트를 복사했습니다.');
                });
            }}
          />
          <TooltipIcon
            name="refresh"
            size={24}
            targetRect={targetRect}
            setTargetRect={setTargetRect}
            onClick={() => {
              openAlertModal({
                content: '수정한 내용을 초기화 하시겠습니까?',
                okText: '초기화',
                onOk: initializeUpdateInfo,
              });
            }}
          />
        </Flex>
      </div>
    </Container>
  );
};

const Container = styled.div`
  flex: 0 0 356px;
  word-break: break-word;
  border-radius: 8px;
  border: 1px solid ${palette.GRAY30};
  margin-top: 27px;
`;

export default UpdatedIngredientList;
